import React, { useContext, useState, useEffect } from 'react'
import { GlobalContext } from '../GlobalContext';
import { colorsRightAxis } from '../model_comparison/model_comparison_utilies';
import BarChart from './BarChart.js'
import {isSameType} from "../RegionConcepts/RegionUtils";
import ObservationsLineChart from './intervention';
import { useTranslation } from 'react-i18next';

var lodash_1 = require("lodash");

const colorsMitigations = ["#f7fcb9","#d9f0a3","#addd8e","#78c679","#41ab5d","#238443","#006837","#004529"];//"#ffffe5",

const colorsAcceleration = ["#fdae6b", "#fd8d3c", "#f16913", "#d94801", "#a63603", "#7f2704"];//"#fee6ce", "#fdd0a2", 

const defaultEventColor = "#a6cee3"

function compare(a, b) {
    if (a.rate < b.rate) {
      return -1;
    }
    if (a.rate>b.rate) {
      return 1;
    }
    return 0;
}

const EventBarChart = ({width, height}) => {
    const { MitigationData, setMitigationData, AccelerationData, setAccelerationData, EventCategories, 
        InterventionData, PolicyDataset, InterventionType, chartType} = useContext(GlobalContext);
    const [plotData, setPlotData] = useState([])
    const { t } = useTranslation();

    useEffect(() => {
        let M = InterventionData[0].events.all!==undefined?InterventionData[0].events.all:[]
        if(M.length>0){
            InterventionData[0].events.all.forEach(item => {
                if (item.name === InterventionType) {
                    if (item.data.mitigation) {
                    setMitigationData([{type:InterventionType, events:item.data.mitigation.mitigationIntervals}]);
                    }
                    if (item.data.acceleration) {
                    setAccelerationData([{type:InterventionType, events:item.data.acceleration.interval}]);
                    }
                }
                });
        }      
    },[InterventionData, InterventionType])

    const handleEventChange = (entry => {
        if (entry.changedEventTag==="mitigation") {
            let mindex = MitigationData.findIndex(x=>isSameType(x.type, InterventionType) & x.dataset===PolicyDataset)
            let clone = lodash_1.cloneDeep(MitigationData);
            let eventData = clone[mindex].events[entry.changedEventId];
            if (!('timeRange' in eventData)) {
                eventData['timeRange'] = {};
            }
            eventData['timeRange']['begin'] = entry.dateFrom.toISOString().slice(0,10);
            eventData['timeRange']['end'] = entry.dateTo.toISOString().slice(0,10);
            setMitigationData(clone);
        }
        else {
            let aindex = AccelerationData.findIndex(x=>isSameType(x.type, InterventionType))
            let clone = lodash_1.cloneDeep(AccelerationData);
            let eventData = clone[aindex].events[entry.changedEventId];
            if (!('timeRange' in eventData)) {
                eventData['timeRange'] = {};
            }
            eventData['timeRange']['begin'] = entry.dateFrom.toISOString().slice(0,10);
            eventData['timeRange']['end'] = entry.dateTo.toISOString().slice(0,10);
            setAccelerationData(clone);
        }
    })

    useEffect(() => {
        MitigationData[0].events.sort((a, b) => {
            const dateA = new Date(a.timeRange.begin);
            const dateB = new Date(b.timeRange.begin);
            return dateB - dateA;
          });  

        AccelerationData[0].events.sort((a, b) => {
            const dateA = new Date(a.timeRange.begin);
            const dateB = new Date(b.timeRange.begin);
            return dateA - dateB;
          }); 

        var isGetMitigationData = !(MitigationData===undefined || MitigationData.length < 1 || MitigationData[0].events.length < 1 || MitigationData.type==="");
        var isGetAccelerationData = !(AccelerationData===undefined || AccelerationData.length < 1 || AccelerationData.type==="");
        if (!(isGetAccelerationData && isGetMitigationData)) return;
        var mitigation = [];
        var mitigationNames = [];
        var uniqueMitigationNames = [];
        var acceleration = [];
        var accelerationNames = [];
        var uniqueAccelerationNames = [];

        if(isGetMitigationData) {
            let mindex = MitigationData.findIndex(x=>isSameType(x.type, InterventionType));
            mitigation = lodash_1.cloneDeep(MitigationData[mindex].events).sort(compare);
            mitigation.forEach(function (_a, index){
                _a["rate"] = (_a.transmissionReduction.begin + _a.transmissionReduction.end)/2;
            });
            mitigationNames = mitigation.map(function (_a,i){return _a.name});
            uniqueMitigationNames = mitigationNames.filter((item, index)=>mitigationNames.indexOf(item)===index);
        }

        if (isGetAccelerationData) {
            if(AccelerationData[0].events!=null){
            let mindex = AccelerationData.findIndex(x=>isSameType(x.type, InterventionType));
            acceleration = lodash_1.cloneDeep(AccelerationData[mindex].events).sort(compare);
            acceleration.forEach(function (_a, index){
                _a["rate"] =  (_a.transmissionReduction.begin + _a.transmissionReduction.end)/2;
            });
            accelerationNames = acceleration.map(function (_a,i){return _a.name});
            uniqueAccelerationNames = accelerationNames.filter((item, index)=>accelerationNames.indexOf(item)===index);
        }
        }

        var totalEvents = (isGetMitigationData? uniqueMitigationNames.length:1) + (isGetAccelerationData? uniqueAccelerationNames.length:1);
        var barWidth = 10;
        var graphHeight = barWidth*totalEvents;
        if (graphHeight > height*0.99) {
            graphHeight = Math.floor(height*0.99);
            barWidth = Math.floor(graphHeight/totalEvents)
        }
        var shrinkedBarWidth= barWidth*0.9;
        if (isGetMitigationData) {
            mitigation.forEach(function (item, index){
                var id = uniqueMitigationNames.indexOf(item.name)
                item["ycoor"] = id * barWidth + barWidth/2;
                item['ycoorMin'] = Math.max(0, Math.min(item.ycoor-shrinkedBarWidth/2, graphHeight));
                item['ycoorMax'] = Math.max(0, Math.min(item.ycoor+shrinkedBarWidth/2, graphHeight));
            });
            mitigation.forEach(function (_a, index) {
                var color = defaultEventColor
                if (("category" in _a)) {
                    let arr = EventCategories.filter(x=>isSameType(x.type, InterventionType))
                    let colorId = -1
                    if (arr.length > 0)
                        colorId = arr[0]['categories'].findIndex( x => x===_a["category"])
                    if (colorId >= 0)
                        color = colorsRightAxis[colorId]
                }
                _a["color"] = color
            });
        }
        if (isGetAccelerationData) {
            if(AccelerationData[0].events!=null){
            acceleration.forEach(function (item, index){
                const id = uniqueAccelerationNames.indexOf(item.name)
                item["ycoor"] =  -id*barWidth - barWidth;
                item['ycoorMin']  = Math.max(-100, Math.min(item.ycoor-shrinkedBarWidth/2, graphHeight));
                item['ycoorMax']  = Math.max(-100, Math.min(item.ycoor+shrinkedBarWidth/2, graphHeight));
            });
            acceleration.forEach(function (_a, index){
                var color = defaultEventColor
                if (("category" in _a)){
                    let colorId = -1
                    let arr = EventCategories.filter(x=>isSameType(x.type, InterventionType))
                    if (arr.length>0)
                        colorId = arr[0]['categories'].findIndex(x => x===_a["category"])
                    if (colorId >= 0)
                        color = colorsRightAxis[colorId]
                }
                _a["color"] = color
            });
        }
        }
        var plots = mitigation.concat(acceleration).map(function (_a, i) {
            var item ={};
            item['name']      = _a.name;
            item['ycoorMin']  = _a.ycoorMin;
            item['ycoorMax']  = _a.ycoorMax;
            item['beginTime'] = new Date(_a.timeRange.begin);
            var tmp = _a.timeRange.end;
            item['endTime']   = (tmp==="")? new Date() : new Date(tmp);
            item['color']     = _a.color;
            item['key']       = 'event'+i.toString();
            item['tag']       = (i < mitigation.length)? 'mitigation':'acceleration';
            item['tagId']     = (i < mitigation.length)? i:(i-mitigation.length);
            return item;
        });

        setPlotData(plots);
    }, [MitigationData, AccelerationData, EventCategories, InterventionData]);

    return chartType === "Gantt Chart" && plotData.length > 0 ? (
        <div key="EventsBarChart">
          <BarChart
            key="Event-Bars"
            data={plotData}
            size={[width, height]}
            margin={{ top: 20, right: 20, bottom: 20, left: 30 }}
            updateEventData={handleEventChange}
            t={t}
          />
        </div>
      ) : <ObservationsLineChart/>;
      
    }
export default EventBarChart;