import React, { Component, useContext, useState } from 'react'
import * as d3 from 'd3'
import { select } from 'd3-selection'

var formatDate = d3.timeFormat("%Y-%m-%d")

class BarChart extends Component {
   
    constructor(props) {
       super(props)
       this.createBarChart = this.createBarChart.bind(this);
       this.mouseDownHandler = this.mouseDownHandler.bind(this);
       this.mouseUpHandler = this.mouseUpHandler.bind(this);
       this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
       this.state = {pickedEventIndex: -1, 
                data:this.props.data, 
                isShowContextMenu:0, 
                top:20, left:20,
                adjustedEventIndex:-1,
                adjustedEventTag:'',
                adjustedEventBegin:'',
                adjustedEventEnd:'',
                t: this.props.t
            };
       this.pos_label_accelerate = 60;
    }

    componentDidMount() {
       this.createBarChart()
    }

    isStateChanged() { 
        /**bug: after the first time users change the events, the prop data will definitely be different from the state data.
        So this function will always return true since then.**/
        let changed = (this.props.data.length != this.state.data.length);
        if (!changed) {
            for (var i=0; i < this.state.data.length; i++) {
                let s = this.state.data[i]
                let p = this.props.data[i]
                changed = changed || (s!=p)
            }
        }
        return changed;
    }

    componentDidUpdate() {
       if (this.isStateChanged()){
           this.state.data = this.props.data
       }
       select(this.node).selectAll('svg').remove();
       this.createBarChart()
    }

    mouseDownHandler(e) {
        if (this.state.data.length === 0) return;
        let currentTargetRect = e.currentTarget.getBoundingClientRect();
        // Find the offset of the mouse from those coordinates.
        const offset = [e.clientX - currentTargetRect.left-this.props.margin.left, 
                        e.clientY - currentTargetRect.top-this.props.margin.top];

        const y = this.getYaxis()
        const x = this.getXaxis()
        var clickedDate = x.invert(offset[0]);
        var clickedY = y.invert(offset[1]);
        //search the clicked event and draw a boundary to height
        const index = this.state.data.findIndex(d=>{
            return  d.beginTime.getTime()<=clickedDate.getTime() && 
                    clickedDate.getTime()<= d.endTime.getTime() && 
                    clickedY<= d.ycoorMax && clickedY>=d.ycoorMin;});
        if(index>=0){
            this.state.pickedEventIndex=index;
            var d = this.state.data[index];
            this.state.adjustedEventIndex = d['tagId'];
            this.state.adjustedEventTag = d['tag'];
            if(e.button ===0 ) {//left button pressed 
                var highligher = select(this.node).select("g").select(".highlighter")
                if(highligher.style("opacity")===1) return;
                highligher.attr("x",  x(d.beginTime)).attr("y", y(d.ycoorMax))
                    .attr("width", x(d.endTime)-x(d.beginTime)).attr("height", y(d.ycoorMin)-y(d.ycoorMax))
                    .style("opacity", 1);

                select(this.node).select("g").select(".referenceLine")
                    .attr("x1", offset[0])
                    .attr("x2", offset[0])
                    .style("opacity", 1);

                select(this.node).select("g").select(".referenceText")
                    .text(formatDate(clickedDate))
                    .attr("x", offset[0])
                    .style("opacity", 1);

                select(this.node).select("g").select(".referenceBox")
                    .attr("x", offset[0])
                    .style("opacity", 1);
            } 
        }   
    }

    mouseMoveHandler(e){
        var highligher = select(this.node).select("g").select(".highlighter")
        if(this.state.pickedEventIndex<0||highligher.style("opacity")===0) return;
        let currentTargetRect = e.currentTarget.getBoundingClientRect();
        // Find the offset of the mouse from those coordinates.
        const offset = [e.clientX - currentTargetRect.left-this.props.margin.left, 
                        e.clientY - currentTargetRect.top-this.props.margin.top];
        const x = this.getXaxis()
        const y = this.getYaxis()

        var newDate = x.invert(offset[0]);
        var event = this.state.data[this.state.pickedEventIndex];
        if (Math.abs(event.beginTime.getTime()- newDate.getTime()) <= 
                Math.abs(event.endTime.getTime()- newDate.getTime())){
            event.beginTime = newDate;
        }
        else {
            event.endTime = newDate;
        }
        select(this.node).selectAll(".rect_"+event.key)
            .attr("x", x(event.beginTime))
            .attr("width", x(event.endTime)-x(event.beginTime) )
            .attr("y",  y(event.ycoorMax))
            .attr("height", y(event.ycoorMin)-y(event.ycoorMax))
        
        select(this.node).selectAll(".label_"+event.key)
            .attr("x", (function(d) { return (x(event.beginTime) + 
                            (x(event.endTime)-x(event.beginTime))/3); }))
        
        highligher
            .attr("x", x(event.beginTime))
            .attr("width", x(event.endTime)-x(event.beginTime) )
            .attr("y",  y(event.ycoorMax))
            .attr("height", y(event.ycoorMin)-y(event.ycoorMax))

        select(this.node).select("g").select(".referenceLine")
            .attr("x1", offset[0])
            .attr("x2", offset[0])

        select(this.node).select("g").select(".referenceText")
            .text(formatDate(newDate))
            .attr("x", offset[0])

        select(this.node).select("g").select(".referenceBox")
            .attr("x", offset[0])
    }

    mouseUpHandler(){
        var barchart = select(this.node).select("g");
        barchart.select(".highlighter")
            .style("opacity", 0);
        
        select(this.node).select("g").select(".referenceLine")
            .style("opacity", 0);

        select(this.node).select("g").select(".referenceText")
            .style("opacity", 0);

        select(this.node).select("g").select(".referenceBox")
            .style("opacity", 0);

        if (this.state.pickedEventIndex < 0) return;
        var event = this.state.data[this.state.pickedEventIndex];
        this.state.adjustedEventBegin = event.beginTime;
        this.state.adjustedEventEnd   = event.endTime;
        this.state.pickedEventIndex=-1;
        this.props.updateEventData({  
            changedEventId: this.state.adjustedEventIndex,
            changedEventTag: this.state.adjustedEventTag,
            dateFrom: this.state.adjustedEventBegin,
            dateTo:  this.state.adjustedEventEnd})
        }

    getCanvasWidth(){return this.props.size[0] - this.props.margin.left - this.props.margin.right;}
    getCanvasHeight(){return this.props.size[1] - this.props.margin.top - this.props.margin.bottom;}

    getYaxis(){
        const height = this.getCanvasHeight()
        var minval = d3.min(this.state.data, function(d){ return d.ycoorMin;});
        return d3.scaleLinear()
            .domain([minval>(-this.pos_label_accelerate)? (-this.pos_label_accelerate):minval, 
                    d3.max(this.state.data, function(d){ return d.ycoorMax;})])
            .range([height,0]).interpolate(d3.interpolateRound);}

    getXaxis(){
        const width = this.getCanvasWidth()
        return d3.scaleTime()
            .domain([d3.min(this.state.data, function(d){ return d.beginTime;}),
                     d3.max(this.state.data, function(d){ return d.endTime;})])
            .range([0, width]);}
    
    createBarChart() {
        const node = this.node;
        const width = this.getCanvasWidth()
        const height = this.getCanvasHeight()
        const y = this.getYaxis()
        const x = this.getXaxis()
        var barchart = select(node).append("svg")
             .attr("width", width + this.props.margin.left + this.props.margin.right)
             .attr("height", height + this.props.margin.top + this.props.margin.bottom)
          .append("g")
             .attr("transform", "translate(" + this.props.margin.left + "," + this.props.margin.top + ")");
 
        // append the rectangles for the bar chart
        barchart.selectAll(".bar")
             .data(this.state.data)
          .enter().append("rect")
             .attr("class", function(d) {return "rect_"+d.key; })
             .attr("x", function(d) {return x(d.beginTime); })
             .attr("width", function(d) {return x(d.endTime)-x(d.beginTime); } )
             .attr("y", function(d) { return y(d.ycoorMax); })
             .attr("height", function(d) {return y(d.ycoorMin)-y(d.ycoorMax);})
             .attr("fill", function(d) {return d.color; }).style("opacity", 0.6);
        
        barchart.append("rect")
             .attr("class", "highlighter")
             .attr("x",  x(this.state.data[0].beginTime)).attr("y", y(this.state.data[0].ycoorMax))
             .attr("width", 1).attr("height", 1)
             .attr("stroke", "pink").attr("stroke-width", 3)
             .attr("fill-opacity","0")
             .style("opacity", 0);
        

        barchart.append("rect")
            .attr("class", "referenceBox")
            .attr("x",  10).attr("y", 8)
            .attr("width", 80).attr("height", 14)
            .attr("stroke", "pink").attr("stroke-width", 3)
            .attr("fill","pink")
            .style("opacity", 0)

        barchart.append("text")
            .attr("class", "referenceText")
            .attr("x", 10)
            .attr("y", 10)
            .attr("dy", ".75em")
            .text("tx")
            .style("opacity", 0)

        barchart.append("text")
        .attr("class", "accelerationLabel")
        .attr("dy", ".75em")
        .text(this.state.t("Exacerbate"))
        .attr("transform", "translate(-25, "+y(-this.pos_label_accelerate).toString()+") rotate(270)")
        .style("opacity", 1)//.style('font-size', '.3em')

        barchart.append("text")
        .attr("class", "mitigationLabel")
        .attr("dy", ".75em")
        .text(this.state.t('Mitigate'))
        .style("opacity", 1)
        .attr("transform", "translate(-25,"+(y(20)).toString()+") rotate(270)")

        barchart.append("line")
            .attr("class", "referenceLine")
            .style("stroke", "pink")
            .style("stroke-width", 2)
            .attr("x1", 10).attr("y1", 0)
            .attr("x2", 10).attr("y2", height)
            .style("opacity", 0)
            .style("stroke-dasharray","5,5");
        
        let eventsPerName = {}
        this.state.data.forEach(element => {
            if ((element.name in eventsPerName) === false){
                eventsPerName[element.name] = []
            }
            eventsPerName[element.name].push({
                'key': element.key, 'timeTag': element.beginTime.getTime(), 
                'days': (element.endTime.getTime()-element.beginTime.getTime())/(1000*3600*24)}) 
        }); 
        let eventNamesForDisplay = {}
        Object.keys(eventsPerName).forEach(name => {
            let eventSet = eventsPerName[name]
            if (eventSet.length === 1) {
                eventNamesForDisplay[eventSet[0].key] = this.state.t(name)
            } else {
                eventSet.sort((a,b)=>{return a.timeTag - b.timeTag})
                eventNamesForDisplay[eventSet[0].key] = this.state.t(name)
                for (let i = 1; i < eventSet.length; i++) {
                    let e = eventSet[i]
                    eventNamesForDisplay[e.key] = ''
                }
            }
        })

        const hoverDiv = document.getElementById('intervention-hover');
        function updateCardContent(d) {
            var left = d.pageX + 'px';
            var top = d.pageY + 'px';
            hoverDiv.style.visibility = 'visible';
            let hoveredEvent = typeof d.target.__data__==="undefined"?"":d.target.__data__.name?d.target.__data__.name:""
            let hoveredDate = typeof d.target.__data__==="undefined"?"":d.target.__data__.beginTime?d.target.__data__.beginTime.toISOString().split('T')[0]:""
            hoverDiv.querySelector('.card-body').innerHTML = `Event: ${hoveredEvent}<br> Start Date: ${hoveredDate}<br>`;
            hoverDiv.style.left = left;
            hoverDiv.style.top = top;
        }
        function updateCardContentOnMouseOut() {
            hoverDiv.style.visibility = 'hidden';
        }

        barchart.selectAll(".text")        
          .data(this.state.data)
        .enter()
          .append("text").style("font", "13px sans-serif")
          .attr("class", function(d) {return "label_" + d.key;})
          .attr("x",     function(d) {return (x(d.beginTime) + 5); })
          .attr("y",     function(d) {return y(d.ycoorMin + (d.ycoorMax-d.ycoorMin)*0.8); })
          .attr("dy", ".75em")
          .text(function(d) { return eventNamesForDisplay[d.key];})
          .on('mouseover', function(d) {
            updateCardContent(d); // Update the Card content based on the hovered element
            d3.select(this).style('fill', 'blue');
          })
          .on('mouseout', function(d) {
            updateCardContentOnMouseOut(d); // Update the Card content based on the hovered element
            d3.select(this).style('fill', 'black');
          })
 
        barchart.append("g").style("font", "13px sans-serif").style("color","#636363")
                            .attr("transform", "translate(0," + y(0) + ")")
                            .call(d3.axisBottom(x)
                            .ticks(3).tickFormat(d3.timeFormat("%Y-%m-%d")));
        barchart.append("g").style("stroke","gray").call(d3.axisLeft(y).ticks(0));
    }


    render() {
       return (
        <div style={{width: this.props.size[0], height: this.props.size[1], overflow: 'auto' }}>
       <svg ref={node => this.node = node} width={this.props.size[0]} height={this.props.size[1]} 
                    onMouseMove={this.mouseMoveHandler} 
                    onMouseDown={this.mouseDownHandler} 
                    onMouseUp={this.mouseUpHandler}/>
                    </div>
       )
    }
 }

 export default BarChart;