import React, {useState, useContext, useEffect} from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../GlobalContext';
import { isSameRegion } from '../RegionConcepts/RegionUtils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../ReactDatePickerStyles.css';

const panelStyle = {
    backgroundColor: '#f8f9fa'
}

const elmntStyle = {
    margin: '2px 5px 10px 5px',
    display: 'flex'
}
const divStyle = {
    textAlign: 'left',
    display: 'inline-block',
    marginLeft: '10px'
}
const datepicker = {
    width: '50%'
}

function MapControlPanelBottom() {
    const { t } = useTranslation();
    const {StateInfo, PickedCountry, MapDataCategoryList, RegionOnMap, setRegionOnMap, ProvinceInfo, PickedDepartment, setPickedDepartment, startDate, setStartDate, endDate, setEndDate, setMapDataCategory, MapDataCategory, CaseValueList, CaseValue, setCaseValue} = useContext(GlobalContext);
    const {loadStateInfo, loadProvinceInfo, CaseType, PickedCaseType, setPickedCaseType} = useContext(GlobalContext)
    const [dropdownOpen_department, setDropdownOpen_department] = useState(false);
    const toggle_department = () => setDropdownOpen_department(prevState => !prevState);
    const [dropdownOpen_province, setDropdownOpen_province] = useState(false);
    const toggle_province = () => setDropdownOpen_province(prevState => !prevState);
    const [dropdownOpen_type, setDropdownOpen_type] = useState(false);
    const toggle_type= () => setDropdownOpen_type(prevState => !prevState);
    var curr = new Date();
    curr.setDate(curr.getDate() -7);

    useEffect(()=>{
        loadStateInfo(PickedCountry);
        loadProvinceInfo(PickedDepartment);
      }, [PickedCountry,PickedDepartment]);

    const handleClick_Department = (regionName) => () => {
        let selectedRegion = (regionName === '')? [PickedCountry] : [PickedCountry, regionName];
        if (isSameRegion(selectedRegion, RegionOnMap)) return;
        setRegionOnMap(selectedRegion);
        setPickedDepartment(regionName)
    }

    const handleClick_Province = (provinceName) => () => {
        let selectedProvince = (provinceName === '')? [PickedCountry, PickedDepartment]:[PickedCountry, PickedDepartment, provinceName];
        if (isSameRegion(selectedProvince, RegionOnMap)) return;
        setRegionOnMap(selectedProvince);
    }

    const handleClick_CaseType = selectedCaseType => () => {
        setPickedCaseType(selectedCaseType)
    }

    const handleStartDateChange=(date)=>{
        setStartDate(date);
    }

    const handleEndDateChange=(date)=>{
        setEndDate(date)
    }

    return(
        <div key="mapview-control" style={panelStyle}>
            <div style={elmntStyle} key="mapview-control-region-selector">
                <Dropdown key='map-control-department-dropdown' isOpen={dropdownOpen_department} toggle={toggle_department} style={{display:"inline-block"}}>
                    <DropdownToggle caret size="sm" color="light">
                        {RegionOnMap.length>1? RegionOnMap[1] : <b>{t('SELECT DEPARTMENT')}</b>}
                    </DropdownToggle>
                    <DropdownMenu style={{ maxHeight: "400px", overflow:"scroll"}}>
                        <DropdownItem key={'dropdown:map:select-a-department'} onClick={handleClick_Department('', '')}><b>{t('SELECT DEPARTMENT')}</b></DropdownItem>
                        {
                        StateInfo?.map((item)=>{
                        return <DropdownItem key={'dropdown:map:'+item['departamento']} onClick={handleClick_Department(item['departamento'])}>
                            {item['departamento']}
                            </DropdownItem>
                        })
                    }
                    </DropdownMenu>
                </Dropdown>
                <Dropdown key='map-control-province-dropdown' isOpen={dropdownOpen_province} toggle={toggle_province} style={{display:"inline-block"}} >
                    <DropdownToggle caret size="sm" color="light">
                    {RegionOnMap.length===3? RegionOnMap[2] : <b>{t('SELECT PROVINCE')}</b>}
                    </DropdownToggle>
                    <DropdownMenu style={{ maxHeight: "400px", overflow:"scroll"}}>
                        <DropdownItem key={'dropdown:map:select-a-province'} onClick={handleClick_Province('', '')}><b>{t('SELECT PROVINCE')}</b></DropdownItem>
                        {
                        ProvinceInfo.map((item)=>{
                        return <DropdownItem key={'dropdown:map:'+item['provincia']} onClick={handleClick_Province(item['provincia'])}>
                            {item['provincia']}
                            </DropdownItem>
                        })
                    }
                    </DropdownMenu>
                </Dropdown>
                <Dropdown key='map-control-type-dropdown' isOpen={dropdownOpen_type} toggle={toggle_type} style={{display:"inline-block"}}>
                    <DropdownToggle caret size="sm" color="light">
                    <b>{t(PickedCaseType)}</b>
                    </DropdownToggle>
                    <DropdownMenu style={{ maxHeight: "400px", overflow:"scroll"}}>
                        <DropdownItem key={'dropdown:map:select-a-type'}></DropdownItem>
                        {
                        CaseType.map((item)=>{
                            return <DropdownItem key={'dropdown:'+item['type']} onClick={handleClick_CaseType(item['type'])}>
                                   {t(item['type'])}
                                </DropdownItem>
                        })
                    }
                    </DropdownMenu>
                </Dropdown>
                <br/>
                <div className='date-picker-component' style={{display:'flex'}}>
                <label><b>{t('START DATE')}</b><DatePicker wrapperClassName={datepicker} selected={startDate} dateFormat="dd/MM/yyyy" onChange={date => handleStartDateChange(date)} startDate={startDate} maxDate={curr}/></label>
                <label><b>{t('END DATE')}</b>
                    {PickedCaseType === 'Daily' ? (
                        <input
                        type="text"
                        value=""  
                        disabled
                        />
                    ) : (
                        <DatePicker
                        selected={endDate}
                        onChange={date => handleEndDateChange(date)}
                        endDate={endDate}
                        maxDate={curr}
                        dateFormat="dd/MM/yyyy"
                        />
                    )}
                    </label>
                </div>
            </div>
            <div style={divStyle} >
                <b>{t('COLOR MAP BY')}{}</b>&nbsp;
                <select value={MapDataCategory} onChange={e => {setMapDataCategory(e.target.value)}}>
                {MapDataCategoryList.map((item, index) => (<option key={index} value={(item.category)}>{t(item.category)}</option>
                ))}
                </select>
            </div>
        </div>

    )
}

export default MapControlPanelBottom;
