import { SET_MESSAGE, CLEAR_MESSAGE } from "./types";
export const setMessage = (message, type = 'neutral') => {
  return {
    type: SET_MESSAGE,
    payload: {
      message,
      type,
    }
  };
};
export const clearMessage = () => ({
  type: CLEAR_MESSAGE,
});

