import {colorConstants} from "../Constants";

function assignColor(data, colorCategory) {
    if(colorCategory==="num_death_cases"){
    data.forEach(item => {
            const hasProvincia = Object.prototype.hasOwnProperty.call(item, 'provincia');
            if (!hasProvincia) {
                data.sort((a, b) => a['population_1000_deaths'] - b['population_1000_deaths']);
              } else {
                data.sort((a, b) => a[colorCategory] - b[colorCategory]);
              }
            })
    const numColors = colorConstants.colorMapDeaths.length;
    const dataLength = data.length;
    const step = dataLength / numColors;

    for (let i = 0; i < dataLength; i++) {
        const colorIndex = Math.min(numColors - 1, Math.floor(i / step));
        if(data[i]['num_death_cases']===0){
            data[i]['color'] = colorConstants.colorMapDeaths[0];
        }
        else{
        data[i]['color'] = colorConstants.colorMapDeaths[colorIndex];
        }
    }
    let dict = Object.assign({}, ...data?.map(x => {
        if (x.hasOwnProperty('departamento')) {
            if(x.hasOwnProperty('provincia')){
                if(x.hasOwnProperty('distrito')){
                    return { [x.distrito]: x }
                }else{
                return { [x.provincia]: x };
                }
            }else{
            return { [x.departamento]: x };}
        } 
    }));

    return dict;
}else if(colorCategory==="num_positive_cases"){
    data.forEach(item => {
        const hasProvincia = Object.prototype.hasOwnProperty.call(item, 'provincia');
        if (!hasProvincia) {
            data.sort((a, b) => a['population_1000_cases'] - b['population_1000_cases']);
          } else {
            data.sort((a, b) => a[colorCategory] - b[colorCategory]);
          }
        })

    const numColors = colorConstants.colorMapCases.length;
    const dataLength = data.length;
    const step = dataLength / numColors;

    for (let i = 0; i < dataLength; i++) {
        const colorIndex = Math.min(numColors - 1, Math.floor(i / step));
        if(data[i]['num_positive_cases']===0){
            data[i]['color'] = colorConstants.colorMapCases[0];
        }
        else{
        data[i]['color'] = colorConstants.colorMapCases[colorIndex];
        }
    }
    let dict = Object.assign({}, ...data?.map(x => {
        if (x.hasOwnProperty('departamento')) {
            if(x.hasOwnProperty('provincia')){
                if(x.hasOwnProperty('distrito')){
                    return { [x.distrito]: x }
                }else{
                return { [x.provincia]: x };
                }
            }else{
            return { [x.departamento]: x };}
        } 
    }));

    return dict;
}
}


function transform(item, colorCategory, CensusInfo) {
    let data = {}
    Object.keys(item).forEach(key => { data[key] = item[key]})
    let filteredData = CensusInfo.filter((item) => item.region === data.departamento)
    data['color'] = colorConstants.colorMapDeaths[0]
    data['population_1000_cases'] = parseFloat(data['num_positive_cases']/filteredData[0].population_1000).toFixed(2)
    data['population_1000_deaths'] = parseFloat(data['num_death_cases']/filteredData[0].population_1000).toFixed(2)
    return data
}

function getObservedDataForMapDisplay(PickedCountry, RegionOnMap, ObservationDataMap){
     let data = []
     let parsedData = []
    if (PickedCountry === 'Peru') {
        if (RegionOnMap.length === 1) {
            data = ObservationDataMap[0]['data']['department']
            if(data !==undefined){
            parsedData= JSON.parse(data)
        }
        
    }else if (RegionOnMap.length ===2) {
            data = ObservationDataMap[0]['data']['province']
            if(data !== undefined){
                parsedData= JSON.parse(data)
            }
        }
        else if (RegionOnMap.length === 3) {
            data = ObservationDataMap[0]['data']['distrito']
            if(data !==undefined){
                parsedData= JSON.parse(data)
            }
        }
    } 
    return parsedData
}

function extractObservationDataForMapDisplay(PickedCountry, RegionOnMap, ObservationDataMap, colorCategory, MapDataCategory,CensusInfo) {
    let raw_data = getObservedDataForMapDisplay(PickedCountry, RegionOnMap, ObservationDataMap)
    let data = raw_data.length>0?raw_data.map(item => transform(item, colorCategory, CensusInfo)):raw_data
    let c = data.length>0?assignColor(data, colorCategory):data
    return c
}

export {extractObservationDataForMapDisplay, getObservedDataForMapDisplay}