import React, { useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

function ChartSelectionForm({ chartData, chartType }) {
    const chartRef = useRef(null);
    const { t } = useTranslation();

    const chartContainerStyle = {
        minHeight: '500px', 
    };

    const getChartTitle = (type) => {
        switch (type) {
            case "Susceptible":
                return t("Number of Predicted Susceptible, Recovered, and New Infectious Individuals");
            case "Recovered":
                return t("SEIR Projections + New Infectious Individuals");
            case "Infectious Individuals Deaths":
                return t("Number of Observed and Predicted Deaths from COVID-19");
            default:
                return "";
        }
    };

    const getYAxisConfiguration = (chartType) => {
        if (chartType === 'Infectious Individuals Deaths') {
            return {
                'right-y-axis': {
                    id: 'right-y-axis',
                    type: 'linear',
                    display: true,
                    position: 'right',
                    min: 0,
                    max: 160,
                    grid: {
                        display: false
                    }
                }
            };
        }
        return {
            'left-y-axis': { 
                id: 'left-y-axis',
                type: 'linear',
                display: true,
                position: 'left',
                min: 0,
                max: 1600000,
                grid: {
                    display: false
                }
            }, 
            'right-y-axis': {
                id: 'right-y-axis',
                type: 'linear',
                display: true,
                position: 'right',
                min: 0,
                max: chartType === 'Recovered' ? 80000 : 14000,
                grid: {
                    display: false
                }
            }
        };
    };

    const transformDataToChartData = () => {
        
        const labels = chartData.map(item => item.date); // Use 'date' field from chartData for labels
        let datasets = [];

        switch (chartType) {
            case "Susceptible":
                datasets = [
                    { label: 'Susceptible', data: chartData.map(item => item.s), borderColor: '#0000FFCC', borderWidth: 2, backgroundColor: '#0000FFCC', yAxisID: 'left-y-axis'},
                    { label: 'Recovered', data: chartData.map(item => item.r), borderColor: '#DC143CCC', borderWidth: 2, backgroundColor: '#DC143CCC', yAxisID: 'left-y-axis' },
                    { label: 'Infected_New', data: chartData.map(item => item.i_new_avg), borderColor: '#00FF00', borderWidth: 2, backgroundColor: '#00FF00', yAxisID: 'right-y-axis' },
                    { label: 'Infected_Total', data: chartData.map(item => item.i_new_obs_avg), borderColor: '#8E24AA', borderWidth: 2, backgroundColor: '#8E24AA', yAxisID: 'right-y-axis' }
                ];
                break;
            case "Recovered":
                datasets = [
                    { label: 'Susceptible', data: chartData.map(item => item.s), borderColor: '#0000FFCC', borderWidth: 2, backgroundColor: '#0000FFCC', yAxisID: 'left-y-axis' },
                    { label: 'Exposed', data: chartData.map(item => item.e), borderColor: '#8E24AA', borderWidth: 2, backgroundColor :'#8E24AA', yAxisID: 'right-y-axis' },
                    { label: 'Infectious', data: chartData.map(item => item.i),  borderColor: '#00FF00', borderWidth: 2, backgroundColor :'#00FF00', yAxisID: 'right-y-axis' },
                    { label: 'Recovered', data: chartData.map(item => item.r), borderColor: '#DC143CCC', borderWidth: 2, backgroundColor :'#DC143CCC', yAxisID: 'left-y-axis' },
                    { label: 'Infected_new', data: chartData.map(item => item.i_new),  borderColor: '#000000', borderWidth: 2, backgroundColor :'#000000', yAxisID: 'right-y-axis' }
                ];
                break;
            case "Infectious Individuals Deaths":
                datasets = [
                    { label: 'Deaths', data: chartData.map(item => item.deaths_obs),  borderColor: '#DC143CCC', borderWidth: 2, backgroundColor :'#DC143CCC', yAxisID: 'right-y-axis' },
                    { label: 'Predicted Deaths', data: chartData.map(item => item.deaths_pred), borderColor: '#0000FFCC', borderWidth: 2,backgroundColor :'#0000FFCC', yAxisID: 'right-y-axis' }
                ];
                break;
            default:
                break;
        }
        return { labels, datasets };
    };

    const chartJsData = transformDataToChartData();

    const options = {
        elements: {
            point: {
              radius: 0,
            },
          },
        scales: {
            x: {
                type: 'category',
                title: {
                    display: true,
                    text: 'Date'
                },
                grid: {
                display : false
                }
            },
            ...getYAxisConfiguration(chartType), // Correctly spreading the y-axis configurations
        },
        plugins: {
            legend: {
                position: 'bottom'
            },
            title: {
                display: true,
                text: getChartTitle(chartType)
            },
            tooltip: { 
            mode: 'index',
            intersect: false,
            callbacks: {
                label: function(context) {
                    let label = context.dataset.label || '';

                    if (label) {
                        label += ': ';
                    }
                    if (context.parsed.y !== null) {
                        label += new Intl.NumberFormat().format(context.parsed.y);
                    }
                    return label;
                }
            }
        }
        },
        responsive: true,
        maintainAspectRatio: false
    };

    return (
        <div style={chartContainerStyle}>
            <Line
                ref={chartRef}
                data={chartJsData}
                options={options}
            />
        </div>
    );
}

export default ChartSelectionForm;

