import { SET_MESSAGE, CLEAR_MESSAGE } from '../actions/types';

const initialState = {};
export default function reducers (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_MESSAGE:
      return { 
        ...state,
        message: payload.message, 
        type: payload.type 
      };
    case CLEAR_MESSAGE:
      return { 
        ...state,
        message: '',
      };
    default:
      return state;
  }
};
