import React, {useContext} from 'react';
import {Card, CardBody, Col} from 'reactstrap';
import Container from 'react-bootstrap/Container';
import { Stack } from 'react-bootstrap';
import MapView from './mapView/MapView';
import Row from 'react-bootstrap/Row';
import LanguagePicker from './components/LanguagePicker';
import {GlobalContext } from './GlobalContext';
import ChartSelectionForm from './ChartSelectionForm';
import EventsView from './events_view/EventsView';
import ModelView from './modelling/ModelView';
import TransitionRatesInput from './TransitionRatesInput';


import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

function PanVis() { 
    const {simulationState} = useContext(GlobalContext);
    const { isLoggedIn } = useSelector(state => state.auth);

    if (!isLoggedIn) {
        return <Navigate to="/login" />;
    }

    return(
        <>
        <div id ='map-hover' style={{visibility: 'hidden', position: 'absolute', zIndex: 999}}>
                <Card style={{boxShadow: '0 1px 4px rgba(0,0,0,.4)', marginRight: '.5em', marginLeft: '.5em', marginTop: '.25em', marginBottom: '.25em'}}>
                    <CardBody style={{padding: '5px'}}>
                        <div>
                            {simulationState.hoveredRegion.region}
                            {Object.keys(simulationState.hoveredRegion).filter((key)=> key != 'region').map(key=>{
                                return <div key={'map-hover-'+key}>
                                    {key}: {simulationState.hoveredRegion[key]}
                                </div>
                            })}
                        </div>
                    </CardBody>
                </Card>
        </div>
        <div id ='intervention-hover' style={{visibility: 'hidden', position: 'absolute', zIndex: 999}}>
                <Card style={{boxShadow: '0 1px 4px rgba(0,0,0,.4)', marginRight: '.5em', marginLeft: '.5em', marginTop: '.25em', marginBottom: '.25em'}}>
                    <CardBody style={{padding: '5px'}}>
                    </CardBody>
                </Card>
        </div>
        <Container fluid>
            <Row xxl={2} lg={2} md={1} sm={1} xl={2} xs={1}>
                <Col>
                    <Stack> 
                        <Card style={{boxShadow: '0 1px 4px rgba(0,0,0,.4)', marginRight: '.01em', marginLeft: '.2em', marginTop: '.25em', marginBottom: '.25em'}}>
                            <CardBody style={{padding: '5px'}}>
                               <MapView/>
                            </CardBody>
                        </Card>
                        <Card style={{boxShadow: '0 1px 4px rgba(0,0,0,.4)', marginRight: '.1em', marginLeft: '.1em', marginTop: '.25em', marginBottom: '.25em'}}>
                        <CardBody style={{padding: 0}}>
                            <EventsView />
                        </CardBody>
                    </Card>
                        
                    </Stack>
                </Col>
                
                <Col xs={5}>
                    <Stack>
                        <Card style={{boxShadow: '0 1px 4px rgba(0,0,0,.4)', marginRight: '.01em', marginLeft: '-0.8em', marginTop: '.25em', marginBottom: '.25em'}}>
                                <CardBody style={{padding: '5px'}}>
                                    <ModelView/>
                                </CardBody>
                        </Card>
                    
                        <Card style={{boxShadow: '0 1px 4px rgba(0,0,0,.4)', marginRight: '.1em', marginLeft: '-0.9em', marginTop: '.25em', marginBottom: '.25em'}}>
                            <CardBody style={{padding: 0}}>
                                <TransitionRatesInput />
                            </CardBody>
                        </Card>
                    </Stack>
                
            </Col>
            </Row>
        </Container>
        </>
    )
}

export default PanVis;