import React, { useState, createContext, useEffect } from 'react';
import {stateDataIOModule, loadarequipaTableData, loadMitigationAccelerationDataModule, provinceDataIOModule, loadPeruCummulativeDemographicInfo, loadPeruDemographicInfo, loadPeruCummulativeDemographicInfoBetweenDates, censusDataModule} from './API';
import {convertDate2Str} from './DateTimeUtils'
import { useSelector } from 'react-redux';
import { getAuthorizationHeader } from './utils';

var lodash_1 = require("lodash");

const GlobalContext =  createContext();

const GlobalContextProvider = props => {
    const [simulationState, setSimulationState] = useState({
        simulationIsRunning: false,
        simulationTimeStep: 0,
        intervalId: null,
                observedData: {
            countyData: [],
            stateData: [],
            startDate: new Date()   
        },
        STLData: {
            countyData: [],
            stateData: []
        },
        simulationSpeed: 400,
        isDraggable: false,
        simulationDataIsLoading: true,
        editModelParams: true,
        hoveredRegion: '',
        selectedCounty: '',
        showCaseGraphs: false,
        showCountyCaseGraphs: false,
        showStateCaseGraphs: false,
        showSimulatedCaseGraphs: false,
        showObservedCaseGraphs: true,
        showSTLCaseGraphs: false,
        showCasePercentage: false
    });
    const [StateInfo, setStateInfo] = useState([{ubigeo: "", departamento:""}])
    const [ProvinceInfo, setProvinceInfo] = useState([{ubigeo: "", departamento:"", provincia:""}])
    const [PickedCountry, setPickedCountry] = useState('Peru');
    const [PickedCaseType, setPickedCaseType] = useState('Cummulative');
    const [RegionOnMap, setRegionOnMap] = useState(['Peru']);
    const [PickedDepartment, setPickedDepartment] = useState('');
    const [ProvinceOnMap, SetProvinceOnMap] = useState(['']);
    const [startDate, setStartDate] = useState(new Date('2020-04-12'));
    const [endDate, setEndDate] = useState(new Date('2020-05-12'));
    const [CaseType, setCaseType] = useState([{type: "Cummulative"},{type: "Daily"}])
    const [ObservationDataMap, setObservationDataMap] = useState([
        {data:[{time:"", observedCases:null, observedDeaths:null}]}]);
    const [MapDataCategoryList, setMapDataCategoryList] = useState([{category: "Observed Cases"},{category: "Observed Deaths"}])
    const [MapDataCategory, setMapDataCategory] = useState('Observed Deaths')
    const [MitigationData, setMitigationData] = useState([{type:"", events:[]}]);
    const [AccelerationData, setAccelerationData] = useState([{type:"", events:[]}]);
    const [SelectedRegionOfEventView, setSelectedRegionOfEventView] = useState(['Peru', 'Amazonas']);
    const [InterventionType, setInterventionType] = useState('Peru');
    const [InterventionData, setInterventionData] = useState([{events:[]}]);
    const [DefaultArequipaData, setDefaultArequipaData] = useState(null);
    const [chartType, setChartType] = useState(false);
    const [EventType, setEventType] = useState('mitigation');
    const [CensusInfo, setCensusInfo] = useState([{region: "", population:0, population_per_1000:0}])

    const { isLoggedIn } = useSelector(state => state.auth);
    
    const loadStateInfo = (country) => {    
        try {
            fetch(stateDataIOModule, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthorizationHeader()
                }, body: JSON.stringify({'country':country})}
            )
            .then(res => res.json())
            .then(data => {
                setStateInfo(data)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const loadCensusInfo= (country) => {    
        try {
            fetch(censusDataModule, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthorizationHeader()
                }, body: JSON.stringify({'country':country})}
            )
            .then(res => res.json())
            .then(data => {
                setCensusInfo(data)
            })
        } catch (error) {
            console.log(error)
        }
    }
    
    const loadMitigationAccelerationData = () => {
        fetch(loadMitigationAccelerationDataModule,  {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": getAuthorizationHeader()
            }, body: JSON.stringify({})})
        .then(response => response.json())
        .then((jsonData) => {
            setInterventionData([{events:jsonData}])
        })
        .catch((error) => {
          console.error(error)
        })
    };

    const loadCummulativeCovidObservationsForMap = ()=> {
        let startDatestr = convertDate2Str(startDate)
        fetch(loadPeruCummulativeDemographicInfo,  {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": getAuthorizationHeader()
            }, body: JSON.stringify({'Date': startDatestr})})
        .then(response => response.json())
        .then((jsonData) => {
            setObservationDataMap([{'data':jsonData}])
        })
        .catch((error) => {
          console.error(error)
        })
      };

    const loadCovidObservationsForMap=()=>{
        let startDatestr = convertDate2Str(startDate)
        fetch(loadPeruDemographicInfo,  {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": getAuthorizationHeader()
            }, body: JSON.stringify({'Date': startDatestr})})
        .then(response => response.json())
        .then((jsonData) => {
            setObservationDataMap([{'data':jsonData}])
        })
        .catch((error) => {
          console.error(error)
        })
    };

    const loadCovidObservationsForMapBetweenDates=()=>{
        let startDatestr = convertDate2Str(startDate)
        let endeDatestr = convertDate2Str(endDate)
        fetch(loadPeruCummulativeDemographicInfoBetweenDates,  {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": getAuthorizationHeader()
            }, body: JSON.stringify({'startDate': startDatestr, 'endDate':endeDatestr})})
        .then(response => response.json())
        .then((jsonData) => {
            setObservationDataMap([{'data':jsonData}])
        })
        .catch((error) => {
          console.error(error)
        })
    };

    const loadProvinceInfo = (region) => {
        fetch(provinceDataIOModule, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": getAuthorizationHeader()
            }, body: JSON.stringify({'region':region})}
        )
        .then(res => res.json())
        .then(data => {
            setProvinceInfo(data)
        });
    }

    const fetchArequipaRatesTable = () => {
        const response =  fetch(loadarequipaTableData,  {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              "Authorization": getAuthorizationHeader()
          }, body: JSON.stringify({})})
      .then(response => response.json())
      .then((jsonData) => {
          setDefaultArequipaData(jsonData)   
      })
      .catch((error) => {
        console.error(error)
      })
    };


    useEffect(()=>{
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        const token = localStorage.token;
        if (!isLoggedIn || !user?.active || !token) {
            return;
        }
        if(convertDate2Str(startDate) === convertDate2Str(endDate) && PickedCaseType==='Cummulative'){
            loadCummulativeCovidObservationsForMap()
        }
        else if(convertDate2Str(startDate) && PickedCaseType==='Daily'){
            loadCovidObservationsForMap()
        }
        else if(convertDate2Str(startDate)!=convertDate2Str(endDate) && PickedCaseType==='Cummulative'){
            loadCovidObservationsForMapBetweenDates()
        }
        loadMitigationAccelerationData();
        loadCensusInfo(PickedCountry);
        fetchArequipaRatesTable();
    },[startDate,endDate,PickedCaseType, InterventionType, isLoggedIn, PickedCountry])
    
    const value = {
        loadCovidObservationsForMap,loadCummulativeCovidObservationsForMap,loadCovidObservationsForMapBetweenDates,
        simulationState, setSimulationState,    
        PickedCountry, setPickedCountry,
        StateInfo, setStateInfo, loadStateInfo,
        RegionOnMap, setRegionOnMap,
        ProvinceOnMap, SetProvinceOnMap,
        ProvinceInfo, setProvinceInfo, loadProvinceInfo,
        PickedDepartment, setPickedDepartment,
        startDate,setStartDate, endDate, setEndDate,
        CaseType,setCaseType,
        PickedCaseType, setPickedCaseType,
        MapDataCategoryList, setMapDataCategoryList,
        ObservationDataMap,setObservationDataMap,
        MapDataCategory, setMapDataCategory,
        MitigationData, setMitigationData,
        SelectedRegionOfEventView, setSelectedRegionOfEventView,
        AccelerationData, setAccelerationData,
        InterventionType, setInterventionType,
        InterventionData, setInterventionData,
        chartType, setChartType,
        EventType, setEventType, CensusInfo,
        DefaultArequipaData, setDefaultArequipaData
    }

    return (
        <GlobalContext.Provider value={value}>
            {props.children}
        </GlobalContext.Provider>
    );
}

export {GlobalContext, GlobalContextProvider};
