import React, { useState, useEffect, useContext } from 'react';
import ChartSelectionForm from './ChartSelectionForm';
import { loadarequipaModelOutput, resetArequipaModelOutput } from './API';
import { getAuthorizationHeader } from './utils';
import { addArequipaCurrentData } from './API';
import { GlobalContext} from './GlobalContext';
import { useSelector } from "react-redux";

// Define the styles
const layoutStyle = {
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
};

const topSectionStyle = {
  height: '30%',
  display: 'flex',
  overflow: 'auto', 
  flexDirection: 'row',
  background: '#f2f2f2',
  marginBottom: '0',
};

const bottomSectionStyle = {
  height: '70%',
  overflow: 'auto', 
  padding: '0',
  paddingTop: '0px', 
  marginTop: '0',
};

const tableListStyle = {
  width: '25%',
  background: '#f2f2f2',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  height: '100%', 
  overflowY: 'auto',
};

const tableOperationsStyle = {
  width: '75%',
  padding: '10px',
  height: '100%', 
  overflowY: 'auto', 
};

const controlButtonStyle = {
  padding: '4px 8px', 
  marginRight: '5px', 
  marginBottom: '5px',
  backgroundColor: '#841617',
  color: 'white',
  border: 'none',
  cursor: 'pointer',
  borderRadius: '4px',
  fontSize: '14px', 
};

const tableStyle = {
  width: 'calc(100% - 20px)', 
  borderCollapse: 'collapse',
  marginTop: '20px',
  marginLeft: '10px', 
  marginRight: '10px', 
  marginBottom: '20px', 
};

const thTdStyle = {
  padding: '4px 12px', 
  border: '1px solid #ddd',
  textAlign: 'left',
  fontSize: '14px', 
};

const inputStyle = {
  padding: '2px',
  margin: '2px',
  borderRadius: '2px',
  border: '1px solid #ddd',
  fontSize: '14px',
};

const initialTextStyle = {
  color: '#333', 
  padding: '50px',
  textAlign: 'center',
  backgroundColor: '#fff', 
  borderRadius: '16px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  margin: '5px 50px',
};

// Default table data
const defaultData = {
  "transition_S_E": [
    ["2020-04-01", "2020-07-01", 1]
  ],
  "transition_S_R": [
    ["2021-04-01", "2021-08-31", 0.000214286]
  ],
  "transition_R_S": [
    ["2020-10-01", "2023-01-15", 0.002222222],
  ],
};

const TransitionRatesInput = () => {
  const {DefaultArequipaData, setDefaultArequipaData} = useContext(GlobalContext);
  const [tables, setTables] = useState(defaultData);
  const [data, setData] = useState(defaultData)
  const [activeTable, setActiveTable] = useState(null);
  const [editRowIndex, setEditRowIndex] = useState(null); 
  const [chartData, setChartData] = useState(null);
  const [chartType, setChartType] = useState("Recovered");
  const [fullChartData, setFullChartData] = useState(null);
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [resetArequipa, setResetArequipa] = useState(defaultData);

  useEffect(()=>{
    setTables(DefaultArequipaData!==null?DefaultArequipaData: defaultData)
    setData(DefaultArequipaData!==null?DefaultArequipaData: defaultData)
    setResetArequipa(DefaultArequipaData!==null?DefaultArequipaData: defaultData)
  },[DefaultArequipaData])
  const handleTableSelection = (tableName) => {
    setActiveTable(tableName);
  };

  const handleEditClick = (index) => {
    setEditRowIndex(index);
  };

  const handleResetClick = (index) => {
    const defaultRow = data[activeTable][index];
    const updatedTables = { ...tables };
    updatedTables[activeTable][index] = [...defaultRow];
    setTables(updatedTables);
    setEditRowIndex(null);
  };

  const handleAddRow = (tableName) => {
    const newRow = ["", "", ""]; 
    const updatedTables = { ...tables, [tableName]: [...tables[tableName], newRow] };
    setTables(updatedTables);
  };

  const handleDeleteRow = (tableName, index) => {
  // Create a new array without the deleted row
  const updatedRows = tables[tableName].filter((_, rowIndex) => rowIndex !== index);
  setTables({ ...tables, [tableName]: updatedRows });
};

  const handleChange = (index, column, value) => {
  // Create a new copy of the current table's data
  const updatedRows = tables[activeTable].map((row, rowIndex) => {
    if (index === rowIndex) {
      return [...row.slice(0, column), value, ...row.slice(column + 1)];
    } else {
      return row;
    }
  });

  setTables({ ...tables, [activeTable]: updatedRows });
};

  const handleSave = (index) => {
    setEditRowIndex(null); // Exit edit mode
  };

  const handleChartTypeChange = (e) => {
    setChartType(e.target.value);
  };

  const extractData=(tables, data, chartType, activeTable) =>{
    const dataToSubmit = Object.keys(tables).reduce((acc, tableName) => {
      let tableData = {};
      tables[tableName].forEach((row, index) => {
        // Extracting the source and target from the tableName
        const [source, target] = tableName.split('_').slice(1);
        const key = `${source}->${target}_${index + 1}`;
        tableData[key] = [row[0], row[1], parseFloat(row[2])];
      });
      acc[tableName] = tableData;
      return acc;
    }, {});
  
  
    const isDefault = JSON.stringify(tables) === JSON.stringify(data);
    dataToSubmit.is_default = isDefault;
  
    dataToSubmit.chartType = chartType;
    // Extract startDate and endDate from the first table ("transition_S_E")
    const firstTable = tables["transition_S_E"];
    if (firstTable && firstTable.length > 0) {
      const firstRow = firstTable[0];
      dataToSubmit.startDate = firstRow[0];
    }
  
    const activeTableData = tables[activeTable];
    if (activeTableData && activeTableData.length > 0) {
      const lastRow = activeTableData[activeTableData.length - 1];
      dataToSubmit.endDate = lastRow[1];
    }
  
    const dataToSend = {};
    for (const key in dataToSubmit) {
      // Check if the value associated with the key is an object and not the chartType, endDate, startDate, or is_default
      if (typeof dataToSubmit[key] === 'object' && !['chartType', 'endDate', 'startDate', 'is_default'].includes(key)) {
        // Add the object to the data to send
        dataToSend[key] = dataToSubmit[key];
      }
    }
    return { dataToSend, dataToSubmit };
  }
  

  const fetchDataForChartType = async (isSubmit, isReset) => {
    const { dataToSend, dataToSubmit } = extractData(tables, data, chartType, activeTable);
    const role = user.role
    try {
      const response = await fetch(loadarequipaModelOutput, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' ,
                    'Authorization': getAuthorizationHeader()},
        body: JSON.stringify({...dataToSubmit, role, isSubmit, isReset}),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const apiData = await response.json();
      setFullChartData(apiData);
      setChartData(apiData);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
}

useEffect(() => {
  fetchDataForChartType(false, false);
}, [chartType, resetArequipa]);

const handleSubmit = async () => {
  const { dataToSend, dataToSubmit } = extractData(tables, data, chartType, activeTable);
  fetchDataForChartType(true, false);
};

const handleSimulate = async () => {
  const { dataToSend, dataToSubmit } = extractData(tables, data, chartType, activeTable);
  fetchDataForChartType(false, false);
};

const handleReset = () => {
  const response =  fetch(resetArequipaModelOutput,  {
    method: 'POST',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": getAuthorizationHeader()
    }, body: JSON.stringify({})})
.then(response => response.json())
.then((jsonData) => {
    setDefaultArequipaData(jsonData)
})
.catch((error) => {
  console.error(error)
})
};

const getFilteredChartData = () => {
    if (!fullChartData) return null;

    switch (chartType) {
      case "Susceptible":
        return fullChartData.map(({ date, s, r, i_new_avg, i_new_obs_avg }) => ({
          date, s, r, i_new_avg, i_new_obs_avg
        }));
      case "Recovered":
        return fullChartData.map(({ date, s, e, i, r, i_new }) => ({
          date, s, e, i, r, i_new
        }));
      case "Infectious Individuals Deaths":
        return fullChartData.map(({ date, deaths_pred, deaths_obs }) => ({
          date, deaths_pred, deaths_obs
        }));
      default:
        return [];
    }
  };

  const renderTableList = () => (
    <div style={tableListStyle}>
      {Object.keys(tables).map((tableName) => (
        <button
          key={tableName}
          style={controlButtonStyle}
          onClick={() => handleTableSelection(tableName)}
        >
          {tableName}
        </button>
      ))}
      <button style={controlButtonStyle} onClick={handleSimulate}>
        Simulate
      </button>
      {isLoggedIn && user.role === "admin" && (
  <>
    <button style={controlButtonStyle} onClick={handleSubmit}>
      Submit Data
    </button>
    <button style={controlButtonStyle} onClick={handleReset}>
      Reset
    </button>
  </>
)}

    </div>
  );

  const renderInitialText = () => (
    <div style={initialTextStyle}>
      <p>Please select a table to view and edit the transition rates.</p>
      <p>S - Susceptible</p>
      <p>E - Effected</p>
      <p>I - Infectious</p>
      <p>R - Recovery</p>    
    </div>
  );

  const renderRadioButtons = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', margin: '10px 0' }}>
        <label>
          <input
            type="radio"
            name="chartType"
            value="Recovered"
            checked={chartType === "Recovered"}
            onChange={handleChartTypeChange}
          />
          SEIR Projections
        </label>
        <label>
          <input
            type="radio"
            name="chartType"
            value="Susceptible"
            checked={chartType === "Susceptible"}
            onChange={handleChartTypeChange}
          />
          SEIR with Observed Data
        </label>
        <label>
          <input
            type="radio"
            name="chartType"
            value="Infectious Individuals Deaths"
            checked={chartType === "Infectious Individuals Deaths"}
            onChange={handleChartTypeChange}
          />
          Observed and Predicted Deaths
        </label>
      </div>
    );
  };

  const renderTable = (tableName) => {
  if (!tableName) return null;

  return (
    <div style={{ height: '100%', overflowY: 'auto' }}>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={thTdStyle}>Start Date</th>
            <th style={thTdStyle}>End Date</th>
            <th style={thTdStyle}>Rate</th>
            <th style={thTdStyle}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tables[tableName].map((row, index) => (
            <tr key={index}>
              <td style={thTdStyle}>
                {editRowIndex === index ? (
                  <input
                    type="date"
                    style={inputStyle}
                    value={row[0]}
                    onChange={(e) => handleChange(index, 0, e.target.value)}
                  />
                ) : (
                  row[0]
                )}
              </td>
              <td style={thTdStyle}>
                {editRowIndex === index ? (
                  <input
                    type="date"
                    style={inputStyle}
                    value={row[1]}
                    onChange={(e) => handleChange(index, 1, e.target.value)}
                  />
                ) : (
                  row[1]
                )}
              </td>
              <td style={thTdStyle}>
                {editRowIndex === index ? (
                  <input
                    type="number"
                    step="0.000001"
                    style={inputStyle}
                    value={row[2]}
                    onChange={(e) => handleChange(index, 2, e.target.value)}
                  />
                ) : (
                  row[2]
                )}
              </td>
              <td style={thTdStyle}>
                {editRowIndex === index ? (
                  <>
                    <button style={controlButtonStyle} onClick={() => handleSave(index)}>Save</button>
                    {index >= data[tableName].length ? (
                      <button style={controlButtonStyle} onClick={() => handleDeleteRow(tableName, index)}>Delete</button>
                    ) : (
                      <button style={controlButtonStyle} onClick={() => handleResetClick(index)}>Reset</button>
                    )}
                  </>
                ) : (
                  <button style={controlButtonStyle} onClick={() => handleEditClick(index)}>Edit</button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ textAlign: 'right', padding: '10px' }}>
        <button
          style={controlButtonStyle}
          onClick={() => handleAddRow(tableName)}
        >
          Add Row
        </button>
      </div>
    </div>
  );
};

  return (
    <div style={layoutStyle}>
      <div style={{ textAlign: "center"}}>
        <h3>Compartmental Transmission Model for Arequipa</h3>
      </div>
      <div style={topSectionStyle}>
        {renderTableList()}
        {activeTable ? renderTable(activeTable) : renderInitialText()}
      </div>
      <div style={bottomSectionStyle}>
        {renderRadioButtons()}
        {chartData && <ChartSelectionForm chartData={getFilteredChartData()} chartType={chartType} />}
      </div>
    </div>
  );
};

export default TransitionRatesInput;

