import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
    SET_USERS,
    SET_USER,
    DELETE_USER,
  } from "./types";
  import AuthService from "../services/auth";
import { setMessage } from "./message";
  
  export const register = (username, email, password) => (dispatch) => {
    return AuthService.register(username, email, password).then(
      (response) => {
        dispatch({
          type: REGISTER_SUCCESS,
        });
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: REGISTER_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: {
            message: message,
            type: "danger",
          }
        });
        return Promise.reject();
      }
    );
  };
  export const login = (username, password) => (dispatch) => {
    return AuthService.login(username, password).then(
      (data) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data.user, token: data.token },
        });
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: LOGIN_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: {
            message: message,
            type: "danger",
          }
        });
        return Promise.reject();
      }
    );
  };
  export const logout = () => (dispatch) => {
    AuthService.logout();
    dispatch({
      type: LOGOUT,
    });
    dispatch({
      type: SET_MESSAGE,
      payload: {
        message: "Logged out successfully!",
        type: "success",
      }
    })
  };
  export const activateUser = (id, activate) => (dispatch) => {
    return AuthService.activateUser(id, activate).then(
        (response) => {
          dispatch({
            type: SET_MESSAGE,
            payload: {
              message: `User ${activate ? "activated" : "unactivated"} successfully!`,
              type: "success",
            }
          });
          dispatch({
            type: SET_USER,
            payload: response.data.user,
          });
          return Promise.resolve();
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          return Promise.reject();
        }
      );
  }
  export const deleteUser = (id) => (dispatch) => {
    return AuthService.deleteUser(id).then(
        (response) => {
          dispatch({
            type: SET_MESSAGE,
            payload: {
              message: "User deleted successfully!",
              type: "success",
            },
          });
          dispatch({
            type: DELETE_USER,
            payload: response.data.user,
          });
          return Promise.resolve();
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          return Promise.reject();
        }
      );
}
export const getUsers = () => (dispatch) => {
    return AuthService.getUsers().then(
        (data) => {
          dispatch({
            type: SET_USERS,
            payload: data.users
          });
          return Promise.resolve();
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          return Promise.reject();
        }
      );
}

export const changeRole = (id, role) => (dispatch) => {
  return AuthService.changeRole(id, role).then(
      (response) => {
        dispatch(setMessage("Role changed successfully!"));
        dispatch({
          type: SET_USER,
          payload: response.data.user,
        });
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch(setMessage(message));
        return Promise.reject();
      }
    );
}
