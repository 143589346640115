// This screen is for use by the admin to manage users

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Chip, Grid, Switch, Typography } from "@mui/joy";
import { deleteUser, activateUser, getUsers, changeRole } from "../actions/auth";
import { useTranslation } from 'react-i18next';

const ManageUsers = () => {
    const { message } = useSelector((state) => state.message);
    const { users } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    // If users is null, get users from the server
    React.useEffect(() => {
        if (!users) {
            dispatch(getUsers());
        }
    }, [users, dispatch]);

    if (users?.length === 0) return (
        <Box sx={{ p: 5 }}>
            <Typography level="h1" textColor="text.secondary" mb={3}>
                'Manage Users'
            </Typography>
            <Typography level="body-md">No users (apart from you) found</Typography>
        </Box>
    );

    return (
        <Box sx={{ p: 5 }}>
            <Typography level="h1" textColor="text.secondary" mb={3}>
                {t('Manage Users')}
            </Typography>
            {users?.map((user) => (
                <Grid container gap={2}  key={user.email} sx={{ mb: 4, justifyItems: 'center', alignItems: 'center', justifyContent: 'space-between' }} >
                    <Grid xs={5}>
                        <Typography level="body-lg" textColor="text.secondary">
                            {user.email}
                        </Typography>
                    </Grid>
                    <Grid container xs={2} sx={{ justifyItems: 'center', alignItems: 'center' }} >
                        <Chip size="lg" variant="outlined" onClick={() => dispatch(changeRole(user.id, user.role === "admin" ? "user" : "admin"))}>
                            {t(user.role.toUpperCase())}
                        </Chip>
                    </Grid>
                    <Switch checked={user.active} onChange={() => dispatch(activateUser(user.id, !user.active))} />
                    <Button onClick={() => dispatch(deleteUser(user.id))}>{t('Delete')}</Button>
                </Grid>
            ))}
        </Box>
    );
};

export default ManageUsers;
