import { Box, Button, Input, Typography } from "@mui/joy";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../actions/message";

import { register } from "../actions/auth";
import { Link, Navigate, useNavigate } from "react-router-dom";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.auth);

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleRegister = (e) => {
    e.preventDefault();
    if (email && password) {
      dispatch(register(email, password))
        .then(() => {
          dispatch(setMessage("Registration was successful! You cannot login until an admin activates your account.", 'warning'));
          navigate("/login");
        })
        .catch(console.log);
    }
  };
  return (
    <Box sx={{ p: 5 }}>
      <form onSubmit={handleRegister}>
        <>
          <Typography level="label" textColor="text.secondary">
            Email
          </Typography>
          <Input
            sx={{ mb: 1 }}
            type="text"
            value={email}
            onChange={onChangeEmail}
            required
          />

          <Typography level="label" textColor="text.secondary">
            Password
          </Typography>
          <Input
            sx={{ mb: 1 }}
            type="password"
            value={password}
            onChange={onChangePassword}
            required
          />
          <Button type="submit">Register</Button>
        </>
      </form>
      <br />
      <Typography level="label" textColor="text.secondary">
        Already have an account? <Link to="/login">Login</Link>
      </Typography>
    </Box>
  );
};
export default Register;
