import { Route, Routes } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from 'reactstrap';
import { useEffect, useState } from 'react';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import { Button, Snackbar } from '@mui/joy';

import PanVis from '../Panviz';
import Login from '../views/Login';
import Register from '../views/Register';
import ManageUsers from '../views/ManageUsers';
import ErrorPage from '../views/ErrorPage';
import { clearMessage } from '../actions/message';

const AppContent = () => {
    const [open, setOpen] = useState(false);
    const { message, type } = useSelector(state => state.message);
    const dispatch = useDispatch();

    useEffect(() => {
        if (message) setOpen(true);
    }, [message]);

    const handleClose = () => {
        setOpen(false);
        dispatch(clearMessage());
    }

    return (
        <div>
            <Routes>
                <Route path="/" element={<PanVis />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/manage-users" element={<ManageUsers />} />
                <Route path="*" element={<ErrorPage />} />
            </Routes>
            <Snackbar
                variant="soft"
                color={type || 'neutral'}
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
                endDecorator={
                <Button
                    onClick={handleClose}
                    size="sm"
                    variant="soft"
                    color={type || 'neutral'}
                >
                    Dismiss
                </Button>
                }
            >
                {message}
            </Snackbar>
        </div>
    )
}

export default AppContent;
