import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from '../GlobalContext';
import { Line } from "react-chartjs-2";
import { Chart } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import {
  CategoryScale,
  LineController,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { predictionsEndPoint } from "../API";
import { useTranslation } from "react-i18next";
import { getAuthorizationHeader } from "../utils";

Chart.register(
  zoomPlugin,
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function PredictionForm() {
  const [departments] = useState([
    "Amazonas", "Ancash", "Apurimac", "Arequipa", "Ayacucho", "Cajamarca", "Callao",
    "Cusco", "Huancavelica", "Huanuco", "Ica", "Junin", "La Libertad", "Lambayeque",
    "Lima", "Loreto", "Madre de Dios", "Moquegua", "Pasco", "Piura", "Puno",
    "San Martin", "Tacna", "Tumbes", "Ucayali",
  ]);

  const { t } = useTranslation();
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedType, setSelectedType] = useState("deaths");
  const [selectedModel, setSelectedModel] = useState("LSTM");
  const [daysDropdown1, setDaysDropdown1] = useState("7");
  const [daysDropdown2, setDaysDropdown2] = useState("14");
  const [dataType, setDataType] = useState("Processed");
  const [isCumulative, setIsCumulative] = useState("daily");
  const [bottomViewContent, setBottomViewContent] = useState("");
  const [daysArray, setDaysArray] = useState([
    parseInt(daysDropdown1),
    parseInt(daysDropdown2),
  ]);
  const [predictions, setPredictions] = useState([]);
  const [observations, setObservations] = useState([]);
  const [dates, setDates] = useState([]);
  const [showGraph, setShowGraph] = useState(false);
  const [selectionType, setSelectionType] = useState("country");
  const { RegionOnMap, setRegionOnMap, MapDataCategory, setMapDataCategory} = useContext(GlobalContext);

  const chartRef = React.useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const [dropdownOpendept, setDropdownOpendept] = useState(false);
  const toggledept = () => setDropdownOpendept(prevState => !prevState);
  const [dropdownOpentype, setDropdownOpentype] = useState(false);
  const toggletype = () => setDropdownOpentype(prevState => !prevState);
  const [dropdownOpencumm, setDropdownOpencumm] = useState(false);
  const togglecumm = () => setDropdownOpencumm(prevState => !prevState);
  const [dropdownOpenModel, setDropdownOpenModel] = useState(false);
  const toggleModel = () => setDropdownOpenModel(prevState => !prevState);
  const [dropdownOpenData, setDropdownOpenData] = useState(false);
  const toggleData = () => setDropdownOpenData(prevState => !prevState);
  const [dropdownOpenDay1, setDropdownOpenDay1] = useState(false);
  const toggleDay1 = () => setDropdownOpenDay1(prevState => !prevState);
  const [dropdownOpenDay2, setDropdownOpenDay2] = useState(false);
  const toggleDay2 = () => setDropdownOpenDay2(prevState => !prevState);

  useEffect(() => {
    if (RegionOnMap.length === 1 && RegionOnMap[0] === 'Peru') {
      setSelectionType('country');
      setBottomViewContent(`Observed and Predicted for ${RegionOnMap[0]}`);
      setSelectedModel('LSTM')
    } else if (RegionOnMap.length === 2 && RegionOnMap[0] === 'Peru') {
      setSelectionType('department');
      setSelectedDepartment(RegionOnMap[1].charAt(0).toUpperCase() + RegionOnMap[1].slice(1).toLowerCase());
      setBottomViewContent(`Observed and Predicted for ${RegionOnMap[0]}, ${RegionOnMap[1].charAt(0).toUpperCase() + RegionOnMap[1].slice(1).toLowerCase()}`);
    }
  }, [RegionOnMap]);

  useEffect(() => {
    if (selectionType === "department" && !selectedDepartment) {
      return;
    }

    if (selectedModel === "DMD") {
      setDaysDropdown1("7");
      setDaysDropdown2("14");
    }

    // if (selectedModel === "Compare Day7" || selectedModel === "Compare Day7" || selectedModel === "DMD") {
    //   const formattedDepartment = selectedDepartment.toUpperCase() ;  
    //   setRegionOnMap(["Peru", formattedDepartment]);
    // }

    let selectedDays = [];
    if (daysDropdown1) selectedDays.push(parseInt(daysDropdown1));
    if (daysDropdown2) selectedDays.push(parseInt(daysDropdown2));

    setDaysArray(selectedDays);

    handleSubmit();
  }, [
    daysDropdown1,
    daysDropdown2,
    selectedType,
    dataType,
    selectionType,
    selectedDepartment,
    selectedModel,
    isCumulative,
  ]);

  

  useEffect(() => {
    if (selectionType === "department" && selectedDepartment) {
      const formattedDepartment = selectedDepartment.toUpperCase() ;
      
      setRegionOnMap(["Peru", formattedDepartment]);
    }
    if(MapDataCategory === "Observed Cases"){
      setSelectedType("cases")
    }else{
      setSelectedType("deaths")
    }
  }, [selectedDepartment, MapDataCategory]);
  
  function extractPredictionsFromItem(item) {
    const predictedKeys = Object.keys(item).filter((key) =>
      key.startsWith("predicted_day_")
    );
    const predictedValues = predictedKeys.map((key) => item[key]);

    return predictedValues;
  }

  let regionSelected = "";
  if (selectionType === "country") {
    regionSelected = "Country";
  } else if (selectionType === "department") {
    regionSelected = selectedDepartment;
  }

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    const responsePredictions = await fetch(predictionsEndPoint, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": getAuthorizationHeader() 
      },
      body: JSON.stringify({
        region: regionSelected,
        type: selectedType,
        model: selectedModel,
        days: daysArray,
        datatype: dataType,
        isCummul: isCumulative,
      }),
    });

    const dataPredictions = await responsePredictions.json();

    setDates(dataPredictions.map((item) => item.fecha_resultado));
    setObservations(dataPredictions.map((item) => parseInt(item.observed)));

    const allPredictions = dataPredictions.map((item) =>
      extractPredictionsFromItem(item)
    );

    if (!allPredictions || allPredictions.length === 0 || !allPredictions[0]) {
      console.error(
        "allPredictions is either empty or not structured correctly."
      );
      return;
    }

    const transposedPredictions = allPredictions[0].map((_, i) =>
      allPredictions.map((row) => row[i])
    );

    setPredictions(transposedPredictions);
    setShowGraph(true);
  };

  const predictionColors = [
    "rgba(0, 0, 255, 0.8)",
    "rgba(0,255,0,1.0)",
    "rgba(220, 20, 60, 0.8)",
  ];

  const predictedDay = [daysDropdown1, daysDropdown2];
  const getLabel = (selectedModel, index) => {
  switch (selectedModel) {
    case 'Compare Day7':
    case 'Compare Day14':
      return index === 0 ? 'DMD' : 'LSTM';
    default:
      return `${t('Predicted Day')} ${predictedDay[index]}`;
  }
};


const data = {
  labels: dates,
  datasets: [
    ...predictions.map((predictionData, index) => ({
      label: getLabel(selectedModel, index),
      data: predictionData,
      borderColor: predictionColors[index],
      borderWidth: 2,
      fill: true,
      backgroundColor: predictionColors[index],
      tension: 0.4,
      hover: {
        pointRadius: 5,
      },
    })),
    {
      label: t("Observations"),
      data: observations,
      borderColor: "rgba(220, 20, 60, 0.8)",
      borderWidth: 2,
      fill: true,
      backgroundColor: "rgba(220, 20, 60, 0.8)",
      tension: 0.4,
      hover: {
        pointRadius: 5,
      },
    },
  ],
};


  return (
    <div>
      {showGraph && (
        <div
          style={{
            width: "100%",
            height: "480px",
            marginTop: "0px",
            padding: "10px",
            backgroundColor: "#F7F7F7",
            borderRadius: "5px",
            position: "relative"
          }}
        >
          <Line
            ref={chartRef}
            data={data}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  grid: {
                    display: false,
                    drawBorder: false,
                  },
                },
                y: {
                  min: 0,
                  grid: {
                    display: false,
                    drawBorder: false,
                  },
                },
              },
              plugins: {
                zoom: {
                  pan: {
                    modifierKey: "ctrl",
                    enabled: true,
                    mode: "xy",
                  },
                  zoom: {
                    drag: {
                      enabled: true,
                    },
                    mode: "x",
                    onwheel: false,
                  },
                },
              },
              legend: {
                display: false,
              },
              elements: {
                point: {
                  radius: 0,
                },
              },
              tooltips: {
                mode: "index",
                intersect: false,
                callbacks: {
                  label: function (tooltipItem, data) {
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    const currentValue = dataset.data[tooltipItem.index];
                    const label = dataset.label;
                    return `${label}: ${currentValue}`;
                  },
                  afterBody: function (tooltipItem, data) {
                    const otherDatasetIndex =
                      tooltipItem[0].datasetIndex === 0 ? 1 : 0;
                    const otherDataset = data.datasets[otherDatasetIndex];
                    const otherValue = otherDataset.data[tooltipItem[0].index];
                    return `${otherDataset.label}: ${otherValue}`;
                  },
                },
              },
              interaction: {
                mode: "index",
                intersect: false,
              },
            }}
          />
          <button
            style={{
              borderRadius: "5px",
              backgroundColor: "#841617",
              color: "white",
              border: "none",
              cursor: "pointer",
              position: "absolute",
              top: "0px",
              right: "0px"
            }}
            onClick={() => chartRef.current?.resetZoom()}
          >
            {t('Reset')}
          </button>
        </div>
      )}

      <div className="bootom-view" style={{ textAlign: 'center' }}>
        <b>
          <label>
            {bottomViewContent}
          </label>
        </b>
      </div>

      <div style={{
        marginTop: "8px",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        padding: "10px",
        backgroundColor: "#F7F7F7",
        borderRadius: "5px",
      }}>
        <div style={{ marginRight: "20px" }}>
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret size="sm" color="light"><b>{selectionType || "Select Level"}</b></DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>Select Level</DropdownItem>
              <DropdownItem onClick={() => {setSelectionType("country"); setRegionOnMap(["Peru"]); setSelectedDepartment("")}} disabled={selectedModel === "DMD"}>
                Country
              </DropdownItem>
              <DropdownItem onClick={() => setSelectionType("department")}>
                Department
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>

        {selectionType === "department" && (
          <div style={{ marginRight: "20px" }}>
            <Dropdown isOpen={dropdownOpendept} toggle={toggledept}>
              <DropdownToggle caret size="sm" color="light">
                <b>{selectedDepartment || "Select Department"}</b>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>Select Department</DropdownItem>
                {departments.map((dept) => (
                  <DropdownItem key={dept} onClick={() => setSelectedDepartment(dept)}>
                    {dept}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        )}

        <div style={{ marginRight: "20px" }}>
          <Dropdown isOpen={dropdownOpentype} toggle={toggletype}>
            <DropdownToggle caret size="sm" color="light">
               <b>{selectedType ? selectedType : t("Type")}</b>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>{t("Select Type")}</DropdownItem>
              <DropdownItem onClick={() => {setSelectedType("cases"); setMapDataCategory("Observed Cases")}} >{t("Cases")}</DropdownItem>
              <DropdownItem onClick={() => {setSelectedType("deaths"); setMapDataCategory("Observed Deaths")}}>{t("Deaths")}</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>

        <div style={{ marginRight: "20px" }}>
          <Dropdown isOpen={dropdownOpencumm} toggle={togglecumm}>
            <DropdownToggle caret size="sm" color="light">
              <b>{t("Data As")}</b>: {isCumulative === "daily" ? t("Daily") : t("Cumulative")}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => setIsCumulative("daily")}>{t("Daily")}</DropdownItem>
              <DropdownItem onClick={() => setIsCumulative("cumulative")}>{t("Cumulative")}</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>

        <div style={{ marginRight: "20px" }}>
          <Dropdown isOpen={dropdownOpenModel} toggle={toggleModel}>
            <DropdownToggle caret size="sm" color="light">
              <b>{t("Model")}</b>: {selectedModel || t("Select Model")}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>{t("Select Model")}</DropdownItem>
              <DropdownItem onClick={() => setSelectedModel("LSTM")} >{t("LSTM")}</DropdownItem>
              <DropdownItem onClick={() => setSelectedModel("DMD")} disabled={selectionType === "country"} >{t("DMD")}</DropdownItem>
              <DropdownItem onClick={() => setSelectedModel("Compare Day7")} disabled={selectionType === "country"} >{t("Compare Day7")}</DropdownItem>
              <DropdownItem onClick={() => setSelectedModel("Compare Day14")} disabled={selectionType === "country"} >{t("Compare Day14")}</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>

        <div style={{ marginRight: "20px" }}>
          <Dropdown isOpen={dropdownOpenData} toggle={toggleData}>
            <DropdownToggle caret size="sm" color="light">
              <b>{t("Data")}</b>: {dataType === "Raw" ? t("Raw Data") : t("Processed Data")}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>{t("Select Data")}</DropdownItem>
              <DropdownItem onClick={() => setDataType("Raw")}>{t("Raw Data")}</DropdownItem>
              <DropdownItem onClick={() => setDataType("Processed")}>{t("Processed Data")}</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>

        <div style={{ marginRight: "20px" }}>
          <Dropdown isOpen={dropdownOpenDay1} toggle={toggleDay1}>
            <DropdownToggle caret size="sm" color="light">
              <b>{t("Day")}</b>: {daysDropdown1 === "None" ? t("None") : daysDropdown1}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>{t("Select Day")}</DropdownItem>
              <DropdownItem
                onClick={() => setDaysDropdown1("None")}
                disabled={selectedModel === "DMD"}
              >
                {t("None")}
              </DropdownItem>
              {Array.from({ length: 14 }, (_, i) => i + 1).map((day) => (
                <DropdownItem
                  key={day}
                  onClick={() => setDaysDropdown1(day)}
                  disabled={selectedModel === "DMD"}
                >
                  {day}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>

        <div style={{ marginRight: "20px" }}>
          <Dropdown isOpen={dropdownOpenDay2} toggle={toggleDay2}>
            <DropdownToggle caret size="sm" color="light">
              <b>{t("Day")}</b>: {daysDropdown2 === "None" ? t("None") : daysDropdown2}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>{t("Select Day")}</DropdownItem>
              <DropdownItem
                onClick={() => setDaysDropdown2("None")}
                disabled={selectedModel === "DMD"}
              >
                {t("None")}
              </DropdownItem>
              {Array.from({ length: 14 }, (_, i) => i + 1).map((day) => (
                <DropdownItem
                  key={day}
                  onClick={() => setDaysDropdown2(day)}
                  disabled={selectedModel === "DMD"}
                >
                  {day}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

export default PredictionForm;
