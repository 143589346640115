import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_USERS,
    DELETE_USER,
    SET_USER,
  } from "../actions/types";
  
  const user = JSON.parse(localStorage.getItem("user")) || null;
  const initialState = user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };
  export default function reducer (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case REGISTER_SUCCESS:
        return {
          ...state,
          isLoggedIn: false,
        };
      case REGISTER_FAIL:
        return {
          ...state,
          isLoggedIn: false,
        };
      case LOGIN_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          user: payload.user,
          token: payload.token,
        };
      case LOGIN_FAIL:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
        };
      case LOGOUT:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
        };
      case SET_USERS:
        return {
          ...state,
          users: payload.filter((user) => user.id !== state.user.id),
        };
      case SET_USER:
        return {
          ...state,
          users: state.users.map((user) => {
            if (user.id === payload.id) {
              return payload;
            }
            return user;
          }),
        };
      case DELETE_USER:
        return {
          ...state,
          users: state.users.filter((user) => user.id !== payload),
        };
      default:
        return state;
    }
  }
  