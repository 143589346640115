import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid, Typography } from "@mui/joy";
import { Dropdown, DropdownButton } from "react-bootstrap";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import { useNavigate } from "react-router-dom";
import './menu.css';

import LanguagePicker from "./LanguagePicker";
import { logout } from "../actions/auth";

const NavBar = () => {
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <Grid className="ribbon" style={{position: 'relative', top: '0',backgroundColor: '#841617',color: '#fff', width: '100%'}}>
        <Grid className="content" style={{display: 'flex'}}>
            <Box className="left-section" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px'}}>
            <img src='OULogo.png' style={{width: '199px', height: '49px', marginBottom: '-4px'}}/>
            </Box>
            <h1 style={{textAlign: 'center', flexGrow: '1', fontSize: 'revert', margin: '15px', marginRight: '143px'}}>Pandemic Visualization(PanViz)-Peru</h1>
            {isLoggedIn && (
                <DropdownButton title={<span>&#9776;</span>} className="menu--dropdown">
                {user.role === "admin" && (
                    <>
                    <Dropdown.Item onClick={() => navigate('/')}>Dashboard</Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate('/manage-users')}>Manage Users</Dropdown.Item>
                    </>
                )}
                <Dropdown.Item onClick={() => {dispatch(logout()); navigate('/login')}}>Logout</Dropdown.Item>
                </DropdownButton>
            )} &nbsp;
            <LanguagePicker/>
        </Grid>
        <Grid style={{display:'flex',justifyContent: 'end'}}>
        <h1 style={{textAlign: 'center', fontWeight: '300',fontSize: '17px', margin: '-32px 0px 10px 308px', flexGrow: '1'}}>Center for Monitoring and Control of Public Health, Arequipa</h1>
        <img src='UnsaLogo.png' style={{width: '135px', height: '57px', marginTop: '-54px'}}/>&nbsp;
        <img src='GCHHI.png' style={{width: '128px', height: '57px', marginTop: '-54px'}}/>
        </Grid>
        </Grid>
    )
}

export default NavBar;
