import { CssVarsProvider } from '@mui/joy';
import { Provider } from 'react-redux';

import { GlobalContextProvider } from './GlobalContext';
import store from './store';
import './App.css';
import NavBar from './components/NavBar';
import Content from './components/AppContent';

function App() {
  return (
    <CssVarsProvider>
      <Provider store={store}>
        <GlobalContextProvider>
          <div id="app" className="d-flex flex-column h-100">
            <NavBar />
            <Content />
          </div>
        </GlobalContextProvider>
      </Provider>
    </CssVarsProvider>
  );
}

export default App;
