
const colorConstants = {
    colorMapDeaths: ["#ffffff", "#fff5f0", "#fee0d2", "#fcbba1", "#fc9272",
               "#fb6a4a", "#ef3b4a", "#cb181d", "#a50f15", "#67000d"],
    colorMapCases: ["#F5FFF5","#E0FFE0","#92BF92","#6AA06A","#3B8A3B","#187218","#0F5A0F","#003D00"],
    colorValues: {
        colorAllSickValue: 'sick',
        colorAllAgeLowSickValue: 'age_low_sick',
        colorAllAgeMidSickValue: 'age_mid_sick',
        colorAllAgeHighSickValue: 'age_high_sick',

        colorNewSickValue: 'new_sick',
        colorNewAgeLowSickValue: 'new_age_low_sick',
        colorNewAgeMidSickValue: 'new_age_mid_sick',
        colorNewAgeHighSickValue: 'new_age_high_sick',

        colorAllDeadValue: 'dead',
        colorAllAgeLowDeadValue: 'age_low_dead',
        colorAllAgeMidDeadValue: 'age_mid_dead',
        colorAllAgeHighDeadValue: 'age_high_dead',

        colorNewDeadValue: 'new_dead',
        colorNewAgeLowDeadValue: 'new_age_low_dead',
        colorNewAgeMidDeadValue: 'new_age_mid_dead',
        colorNewAgeHighDeadValue: 'new_age_high_dead',

        colorAllHospitalizedValue: 'hospitalized',
        colorAllAgeLowHospitalizedValue: 'age_low_hospitalized',
        colorAllAgeMidHospitalizedValue: 'age_mid_hospitalized',
        colorAllAgeHighHospitalizedValue: 'age_high_hospitalized',

        colorNewHospitalizedValue: 'new_hospitalized',
        colorNewAgeLowHospitalizedValue: 'new_age_low_hospitalized',
        colorNewAgeMidHospitalizedValue: 'new_age_mid_hospitalized',
        colorNewAgeHighHospitalizedValue: 'new_age_high_hospitalized',

        colorAllCasesValue: 'all_cases',
        colorNewCasesValue: 'new_cases'
    }
}

const populationConstants = {
    underEighteenValue: 'showUnderEighteen',
    eighteenToSixtyfiveValue: 'showEighteenToSixtyFive',
    sixtyfiveOverValue: 'showSixtyfiveOver',
}

const defaultModelParams = {
    sr_latitude: 35.467511,
    sr_longitude: -97.494620,
    sr_speed: 5.0,
    gp_attack_rate_change: 0,
    di_under_eighteen: 1.10,
    di_eighteen_to_sixtyfive: 1.0,
    di_over_sixtyfive: 0.8,

    dm_media_on: true,
    dm_close_schools_on: true,
    dm_sns_on: true,
    dm_media_reduction: 10.0,
    dm_close_schools_reduction: 15.0,
    dm_sns_reduction: 25.0,
    dm_media_start_day: 2,
    dm_close_schools_start_day: 4,
    dm_sns_start_day: 6,
    dm_media_full_impact_day: 2,
    dm_close_schools_full_impact_day: 5,
    dm_sns_full_impact_day: 7,

    gp_hospital_bed_model: 1,
    gp_mortality_rate: 0.02,
    gp_hospitalization_rate: 0.10,
    gp_typical_hospital_capacity: 0.7,
    gp_mean_time_to_recover: 22,
    gp_mean_time_in_hospital: 14,
    gp_mean_time_to_die: 16
}

const unemploymentDataConstants = {
    filename: 'unemployment-by-county-2017.csv',
    state: 'OK',
    commaSplit: ',',
    countySplit: ' County',
    parishSplit: ' Parish'
}

const observedDataConstants = {
    filename: 'counties.csv',
    forwardSlashSplit: '/',
    dateYear: '2020',
    dateSeparator: '-',
    casesField: colorConstants.colorValues.colorAllSickValue,
    deathsField: colorConstants.colorValues.colorAllDeadValue
}

const STLDataConstants = {
    filename: 'OK_STL_data.csv',
    stateFilter: "State"
}

const timeChartConstants = {
    titles: ['Active Cases', 'Death Cases', 'Need Hospitalization'],
    fieldSeparators: {
        allSeparator: '',
        newSeparator: 'new_',
        suffixSeparator: '_'
    },
    fields: {
        date: 'date',
        cases: 'num_cases'
    }
}

const colorsForDataSource = ['#66c2a5', '#fc8d62', '#8da0cb', '#e78ac3', '#a6d854']

const policyCategories = {OxCGRT:["Winter_Holidays", "Summer_Holidays", "Election", "Fall_reopen", "Thanksgiving", "vaccine"], 
COVIDAMP:['Social distancing','Emergency declarations', 'Travel restrictions', 'Enabling and relief measures','Support for public health and clinical capacity',
  'Contact tracing/Testing', 'Military mobilization','Face mask']}

const ModelOptions = [
    {id:'model-CovidProjection', displayName:'SEIR (CovidProjection)'},
    {id:'model-Oklahoma', displayName:'Oklahoma simulation'},
];

export { colorConstants, populationConstants, defaultModelParams, unemploymentDataConstants, 
         observedDataConstants, STLDataConstants, timeChartConstants, policyCategories,
         colorsForDataSource, ModelOptions};
