import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../GlobalContext";
import { Line } from "react-chartjs-2";
import { Chart } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import annotationPlugin from 'chartjs-plugin-annotation';
import { CategoryScale, LineController, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { observationsEndPoint } from "../API";
import ReactSlider from 'react-slider';
import { useTranslation } from "react-i18next";
import { getAuthorizationHeader } from "../utils";

Chart.register(zoomPlugin, LineController, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, annotationPlugin);

const sliderStyles = {
    horizontalSlider: {
        position: 'relative',
        width: '60%',
        height: '20px',
        margin: '10px auto',
        background: "red",
        marginTop: "1rem",
        marginBottom: "1rem",
        marginLeft: "2rem",
        borderRadius: '10px',
        padding: '10px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
    },
    thumb: {
        width: "12px",
        height: "12px",
        backgroundColor: "#841617",
        borderRadius: "50%",
        cursor: "pointer",
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    thumb0: {
        left: 0,
    },
    thumb1: {
        right: 0,
    },
    track: {
        height: '2px',
        borderRadius: '5px',
        backgroundColor: '#a6cee3',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    track1: (value) => ({
        left: 0,
        width: `${value}%`,
        height: '2px',
        borderRadius: '5px',
        backgroundColor: '#a6cee3',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)'
    }),
    track0: (value) => ({
        right: 0,
        width: `${100 - value}%`,
        height: '2px',
        borderRadius: '5px',
        backgroundColor: '#a6cee3',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)'
    })
};

function ObservationsLineChart() {
    const chartRef = React.useRef(null);
    const { InterventionType, MitigationData, AccelerationData, EventType } = useContext(GlobalContext);
    const [observations, setObservations] = useState([]);
    const [dates, setDates] = useState([]);
    const [sliderValues, setSliderValues] = useState([0, dates.length - 1]);
    const {t} = useTranslation();

    useEffect(() => {
        const fetchObservations = async () => {
            const response = await fetch(observationsEndPoint, {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": getAuthorizationHeader() 
                },
                body: JSON.stringify({
                    region: InterventionType,
                }),
            });

            const data = await response.json();
            setDates(data?.map((item) => formatDate(item.fecha_resultado)));
            setObservations({
                cases: data?.map((item) => parseInt(item.observed_cases, 10)),
                deaths: data?.map((item) => parseInt(item.observed_deaths, 10))
            });
            setSliderValues([0, data.length - 1]);
        };

        fetchObservations();
    }, [InterventionType, EventType]);

    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const getEventAnnotations = () => {
        let annotations = [];
        let currentData = [];

        if (EventType === "acceleration" && AccelerationData[0].events) {
            currentData = AccelerationData[0].events;
        } else if (EventType !== "acceleration" && MitigationData[0].events) {
            currentData = MitigationData[0].events;
        }

        for (let event of currentData) {
            const formattedEventDate = formatDate(event.timeRange.begin);
            if (dates.includes(formattedEventDate)) {
                annotations.push({
                    type: 'line',
                    mode: 'vertical',
                    scaleID: 'x',
                    value: formattedEventDate,
                    borderColor: event.color || 'blue',
                    borderWidth: 2,
                    label: {
                        display: true,
                        content: event.name,
                        enabled: true,
                        position: 'top',
                        rotation: 90,
                        backgroundColor: '#a6cee3',
                        color: 'black',
                    },
                    tooltip: {
                        enabled: false,
                    },
                });
            }
        }
        return annotations;
    };

    const data = {
        labels: dates,
        datasets: [
            {
                label: "Cases",
                data: observations.cases,
                yAxisID: 'yCases',
                borderColor: "rgba(0, 0, 255, 0.8)",
                borderWidth: 2,
                fill: true,
                backgroundColor: "rgba(0, 0, 255, 0.8)",
                tension: 0.4,
                hover: {
                    pointRadius: 5,
                },
            },
            {
                label: "Deaths",
                data: observations.deaths,
                yAxisID: 'yDeaths',
                borderColor: "rgba(220, 20, 60, 0.8)",
                backgroundColor: "rgba(220, 20, 60, 0.8)" ,
                borderWidth: 2,
                fill: false,
                tension: 0.4,
                hover: {
                    pointRadius: 5,
                },
            },
        ],
    };

    return (
        <div style={{
            height : "100%",
            marginTop: "0px",
            padding: "10px",
            backgroundColor: "#F7F7F7",
            borderRadius: "5px",
            position: "relative"
          }}>
            <button
            style={{
              borderRadius: "5px",
              backgroundColor: "#841617",
              color: "white",
              border: "none",
              cursor: "pointer",
              position: "absolute",
              top: "0px",
              right: "0px",
            }}
            onClick={() => chartRef.current?.resetZoom()}
          >
            {t('Reset')}
          </button>
            <Line
                ref={chartRef}
                data={data}
                options={{
                    scales: {
                        x: {
                            min: dates[sliderValues[0]],
                            max: dates[sliderValues[1]],
                            grid: {
                                display: false,
                                drawBorder: false,
                            },
                        },
                        yCases: {
                            position: 'left',
                            grid: {
                                display: false,
                                drawBorder: false,
                            },
                            title: {
                                display: true,
                                text: 'Cases'
                            }
                        },
                        yDeaths: {
                            position: 'right',
                            grid: {
                                display: false,
                                drawBorder: false,
                            },
                            title: {
                                display: true,
                                text: 'Deaths'
                            }
                        }
                    },
                    plugins: {
                        zoom: {
                            pan: {
                                modifierKey: "ctrl",
                                enabled: true,
                                mode: "xy",
                            },
                            zoom: {
                                drag: {
                                    enabled: true,
                                },
                                mode: "x",
                                onWheel: false,
                            },
                        },
                        annotation: {
                            annotations: getEventAnnotations()
                        }
                    },
                    responsive: true,
                    legend: {
                        display: true,
                    },
                    elements: {
                        point: {
                            radius: 0,
                        },
                    },
                }}
            />
            
            <ReactSlider
                ariaValuenow={sliderValues}  
                className="horizontal-slider"
                thumbClassName="thumb"
                trackClassName="track"
                value={sliderValues}
                min={0}
                max={dates.length - 1}
                onChange={newValues => setSliderValues(newValues)}
                renderThumb={(props, state) => (
                    <div 
                        {...props} 
                        style={state.index === 0 ? {...sliderStyles.thumb, ...sliderStyles.thumb0} : {...sliderStyles.thumb, ...sliderStyles.thumb1}}>
                        {dates[state.valueIndex]}
                    </div>
                )}
                renderTrack={(props, state) => (
                    <div {...props} 
                        style={state.index === 0 
                                ? sliderStyles.track0(sliderValues[0] / dates.length * 100) 
                                : sliderStyles.track1(sliderValues[1] / dates.length * 100)}
                    />
                )}
                style={sliderStyles.horizontalSlider}
                pearling
                minDistance={1}
            />
        </div>
    );
}

export default ObservationsLineChart;

