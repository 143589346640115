
import {Shapefile_projections, Shapefiles_Peru} from './RegionConstants'

const getLookup = () => {
    return Shapefiles_Peru
}

const isSameRegion = (regionId1, regionId2) =>{
    if(regionId1.length != regionId2.length) {
        return false
    }

    for(let i=0; i<regionId1.length; i++) {
        if (regionId1[i] != regionId2[i]) {
            return false
        }
    }
    return true
}
const isSameType = (regionId1, regionId2) =>{
    if(regionId1 != regionId2) {
        return false
    }
    return true
}

const isSubRegion = (region, subregion) => {
    if ((region.length +1) != subregion.length) {
        return false
    }
    for(let i=0; i<region.length; i++) {
        if (region[i] !== subregion[i])
            return false
    }
    return true
}

const getShapefileUrl = (region)=>{
    const dictObj = getLookup()
    let adjustRegion = [region[0]]
    if (region.length === 2) {
        adjustRegion.push("Provinces")
    }
    else if (region.length === 3) {
        adjustRegion.push("Districts")
    }
    let arr = dictObj.filter(element => isSameRegion(element.id, adjustRegion))
    return [arr[0].url, arr[0].subregion_key]
}

const getMapProjection = (regionId) => {
   let arr = Shapefile_projections.filter(element=>element.country === regionId[0])
   return arr[0].projection
}

export {getShapefileUrl, isSameRegion, isSubRegion, getMapProjection, isSameType}
