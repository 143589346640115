const IP = process.env.REACT_APP_API_URL || "http://localhost:5000";

export const stateDataIOModule = `${IP}/loadStateDemographicInfo`;
export const provinceDataIOModule = `${IP}/loadProvinceDemographicInfo`;
export const loadPeruDemographicInfo = `${IP}/loadPeruDemographicInfo`;
export const loadPeruCummulativeDemographicInfo = `${IP}/loadPeruCummulativeDemographicInfo`;
export const loadPeruCummulativeDemographicInfoBetweenDates = `${IP}/loadPeruCummulativeDemographicInfoBetweenDates`;
export const predictionsEndPoint = `${IP}/loadpredictionsForPeru`;
export const observationsEndPoint = `${IP}/loadobservationsForPeru`;
export const loadarequipaModelOutput = `${IP}/loadarequipaModelOutput`;
export const addMitigationDataModule = `${IP}/addMitigationData`;
export const addAccelerationDataModule = `${IP}/addAccelerationData`;
export const loadMitigationAccelerationDataModule = `${IP}/loadMitigationAccelerationData`;
export const deleteMitigationDataModule = `${IP}/deleteMitigationEvent`;
export const deleteAccelerationDataModule = `${IP}/deleteAccelerationEvent`;
export const AUTH_API_URL = `${IP}/api/users/`;
export const censusDataModule = `${IP}/loadCensusData`;
export const loadarequipaTableData = `${IP}/loadArequipaTableData`;
export const addArequipaCurrentData = `${IP}/addArequipaCurrentData`;
export const resetArequipaModelOutput = `${IP}/resetArequipaModel`;
