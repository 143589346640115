import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Label } from 'reactstrap';
import { GlobalContext } from '../GlobalContext';

function MapBottomView() {
    const { t } = useTranslation();
    const {ObservationDataMap, RegionOnMap, startDate, endDate} = useContext(GlobalContext);
    const [countsState, setCountsState] = useState({cases: -1, deaths: -1})

    useEffect( () => {
        let countCases = 0, countDeaths = 0
        let data = ObservationDataMap[0]['data']
            if (RegionOnMap.length === 1) {
                let parsedData = {}
                if(data['country']!==undefined && data['country']!=[]){
                parsedData= JSON.parse(data['country'])}
                countCases =  parsedData[0]!==undefined?parsedData[0]['num_positive_cases']:0
                countDeaths = parsedData[0]!==undefined?parsedData[0]['num_death_cases']:0
            } else if (RegionOnMap.length === 2) {
                let parsedData = {}
                if(data['department']!=undefined && data['department']!=[]){
                    parsedData= JSON.parse(data['department'])}
                    countCases =  parsedData[0]!==undefined?parsedData[0]['num_positive_cases']:0
                    countDeaths = parsedData[0]!==undefined?parsedData[0]['num_death_cases']:0
            } else if (RegionOnMap.length === 3){
                let parsedData = {}
                if(data['province']!==undefined && data['province']!=[]){
                    parsedData= JSON.parse(data['province'])}
                    countCases =  parsedData[0]!==undefined?parsedData[0]['num_positive_cases']:0
                    countDeaths = parsedData[0]!==undefined?parsedData[0]['num_death_cases']:0
            }
        setCountsState({cases: countCases, deaths: countDeaths})
}, [RegionOnMap, ObservationDataMap,startDate, endDate])

    return (
       <div className="bootom-view" style={{textAlign: 'center'}}>
        <b>
           <Label>{t('AccumulatedCounts')}&nbsp;&nbsp;</Label>
           <Label>{countsState.cases}&nbsp;{t('CASES')},&nbsp; </Label>
           <Label>{countsState.deaths}&nbsp;{t('DEATHS')}&nbsp;</Label>
           </b>
       </div>
    );
}

export default MapBottomView;
