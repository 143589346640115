import React, {useContext, useState} from 'react';
import { GlobalContext } from '../GlobalContext';
import { Button } from 'reactstrap';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import './EventViewSetting.css'
import { useTranslation } from 'react-i18next';
import {isSameType} from "../RegionConcepts/RegionUtils";
import EventsRegionOptionPanel from './EventsRegionOptionPanel';
import { addMitigationDataModule, addAccelerationDataModule, deleteMitigationDataModule, deleteAccelerationDataModule } from '../API';
import Switch from 'react-switch';
import './CustomSwitch.css'; 
import { getAuthorizationHeader } from '../utils';
import { useSelector } from "react-redux";
import { useEffect } from 'react';

var lodash_1 = require("lodash");

const EventEditor = ()=>{
  const { t } = useTranslation();
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const {MitigationData, setMitigationData,AccelerationData, setAccelerationData,InterventionType, setChartType} = useContext(GlobalContext);
  const [StartDate, setStartDate] = useState(Date.now);
  const [EndDate, setEndDate]   = useState(Date.now);
  const [NewEventName, setNewEventName] = useState('')
  const [EventType, setEventType] = useState('mitigation')
  const [isToggled, setIsToggled] = useState(false);
  const addEventName = ((e)=>{
    setNewEventName(e.target.value)
  })

  const addEvent=((e)=>{
    if (NewEventName!==''){
      let date_start_str = (new Date(StartDate)).toISOString().split('T')[0]
      let date_end_str = (new Date(EndDate)).toISOString().split('T')[0]
      if(EventType==="acceleration") {
        let newItem = ({ color: "#cccccc",name:NewEventName, 
          timeRange:{begin: date_start_str, end: date_end_str},
          transmissionReduction:{begin:0.1, end: 0.15}
        })
        let mindex = AccelerationData.findIndex(x=>isSameType(x.type, InterventionType))
        let clone = lodash_1.cloneDeep(AccelerationData);
        if(clone[mindex].events===null){
          clone[mindex].events = newItem
          setAccelerationData(clone)
        }else{
        clone[mindex].events.push(newItem);
        setAccelerationData(clone)
        }
        if(isLoggedIn && user.role === "admin"){
        fetch(addAccelerationDataModule, {
          method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": getAuthorizationHeader()
            }, body: JSON.stringify({item: newItem, type:InterventionType})
        })
          .then((response) => response.json())
          .catch((error) => {
            console.error(error)
          });
      }
    }
      if(EventType==="mitigation") {
        let newItem = ({ color: "#cccccc",name:NewEventName, 
          timeRange:{begin: date_start_str, end: date_end_str},
          transmissionReduction:{begin:0.1, end: 0.15}
        })
        let mindex = MitigationData.findIndex(x=>isSameType(x.type, InterventionType))
        let clone = lodash_1.cloneDeep(MitigationData);
        clone[mindex].events.push(newItem);
        setMitigationData(clone)
        if(isLoggedIn && user.role === "admin"){
        fetch(addMitigationDataModule, {
          method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": getAuthorizationHeader()
            }, body: JSON.stringify({item: newItem, type:InterventionType})
        })
          .then((response) => response.json())
          .catch((error) => {
            console.error(error)
          });
      }
    }
    }
  })
  const selectEventType = ((e)=>{
    setEventType(e.target.value)
  })

  useEffect(()=>{
    {isToggled? setChartType('Line Chart'):setChartType('Gantt Chart')}
  },[isToggled])

  const deleteEvent=((e)=>{
    if (NewEventName!==''){
      if(EventType === "mitigation") {
        let newItem = NewEventName
        const updatedData = MitigationData[0].events.filter(item => item.name !== newItem);
        setMitigationData([{type: InterventionType, events: updatedData}]);
        fetch(deleteMitigationDataModule, {
          method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": getAuthorizationHeader()
            }, body: JSON.stringify({item: newItem, type:InterventionType})
        })
          .then((response) => response.json())
          .catch((error) => {
            console.error(error)
          });
      }
      if(EventType==="acceleration") {
        let newItem = NewEventName
        const updatedData = AccelerationData[0].events.filter(item => item.name !== newItem);
        setAccelerationData([{type: InterventionType, events: updatedData}]);
        fetch(deleteAccelerationDataModule, {
          method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": getAuthorizationHeader()
            }, body: JSON.stringify({item: newItem, type:InterventionType})
        })
          .then((response) => response.json())
          .catch((error) => {
            console.error(error)
          });
        }
      }
    })

    const handleToggle = () => {
      setIsToggled(!isToggled);
    };

  return (
      <div id="event_editor" style={{background:"#e6ffff"}}>
        <div style={{display: 'flex'}}>
        <h4 style={{textAlign: 'left'}}>{t('EVENT SETTING')}</h4> &nbsp;&nbsp;&nbsp;
        <EventsRegionOptionPanel/>
        <label>
          <Switch
            onChange={handleToggle}
            checked={isToggled}
            className="custom-switch"
          />
        </label>&nbsp;
        <p>{isToggled ? 'Line Chart' :'Gantt Chart'}</p>
        </div>
        
        <div onChange={selectEventType} style={{float:'left'}}>
          <input type="radio" name="eventType" value="mitigation" defaultChecked/>
            &nbsp;{t('MITIGATION')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <input type="radio" name="eventType" value="acceleration"/>
            &nbsp;{t('EXACERBATION')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <br />
        <div key="add-event-panel" style={{display: 'inline-block'}}>
        <label style={{float:'left'}}>{t('BEGIN')}:&nbsp;</label>
        <div className="customDatePickerWidth" style={{float:'left', width:"100px"}}>
          <DatePicker selected={StartDate} dateFormat="dd/MM/yyyy" onChange={date => setStartDate(date)}/>
        </div>
        <label style={{float:'left'}}>&nbsp;&nbsp;{t('END')}:&nbsp;</label>
        <div className="customDatePickerWidth" style={{float:'left', width:"100px"}}>
          <DatePicker selected={EndDate} dateFormat="dd/MM/yyyy" onChange={date => setEndDate(date)}/>
        </div>
        <label style={{float:'left'}}>&nbsp;&nbsp;{t('EVENT NAME')}:&nbsp;
          <input type="text" style={{width:"91px", float:"leaft", height:"25px"}}
                 value={NewEventName} onChange={addEventName}/>
          &nbsp;
        </label>
        <Button variant="dark" style={{width:"83px", height:"25px", 
          marginTop:"-1px", paddingTop:"0px", backgroundColor:'#841617'}} onClick={addEvent}>{t('ADD EVENT')}</Button>&nbsp;
        <Button variant="dark" style={{width:"85px", height:"25px", 
          marginTop:"-1px", paddingTop:"0px", backgroundColor:'#841617'}} onClick={deleteEvent}>{t('DELETE EVENT')}</Button>
        </div>
        
      </div>
  );
}
export default EventEditor;