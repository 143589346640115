import React from "react";
import PredictionForm from "./PredictionForm";
import Stack from 'react-bootstrap/Stack';
import { useTranslation } from 'react-i18next';

function ModelView() {
    const { t } = useTranslation();
    return (
        <div>
            <Stack>
                <h3 style={{ textAlign: "center" }}>{t('COVID 19 Modeling')}</h3>
                <PredictionForm/>
            </Stack>
        </div>
    );

}

export default ModelView;