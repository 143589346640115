import React, {useState, useEffect, useContext} from 'react';
import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps";
import { getMapProjection,getShapefileUrl } from '../RegionConcepts/RegionUtils';
import { GlobalContext} from '../GlobalContext';
import {extractObservationDataForMapDisplay} from './MapDataProcess'
import { colorConstants } from '../Constants';
import { convertDateToEuropeanFormat } from '../DateTimeUtils';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import regionCoordinates from './regionCoordinates.json';
import provinceCoordinates from './provinceCoordinates.json';

const width = 800, height = 750;

function Map() { 
    const {setSimulationState,PickedCountry, RegionOnMap, setRegionOnMap,ObservationDataMap, startDate, MapDataCategory, PickedCaseType, endDate, PickedDepartment, setPickedDepartment, CensusInfo} = useContext(GlobalContext);
    const [geoUrl, setGeoURL] = useState('')
    const [subregionId, setSubRegionId] = useState('')
    const [DataDisplayOnMap, setDataDisplayOnMap] = useState({})
    const [position, setPosition] = useState({ coordinates: [-75, -15], zoom: 9 });
    const {t} = useTranslation();
    let colorCategory = ""

    const getSubRegionId = (geo) => {
        let idName = ''
        let element = geo[subregionId[0]]
        idName = element[subregionId[1]]
        return idName
    }

    useEffect(()=>{
        if(RegionOnMap.length===1){
            setPosition({ coordinates: [-75, -14.5], zoom: 9.5 })
        }
        else if(RegionOnMap.length===2){
            const { coordinates, zoom } = regionCoordinates[RegionOnMap[1]];
            setPosition({ coordinates: coordinates, zoom: zoom })
        }
        else if(RegionOnMap.length===3){
            const { coordinates, zoom } = provinceCoordinates[RegionOnMap[1]][RegionOnMap[2]];
            setPosition({ coordinates: coordinates, zoom: zoom })
        }
        
    }, [RegionOnMap])

    const handleCountyClick = geoId => () => {
        let selectedRegion = (geoId === '')? [PickedCountry] : [PickedCountry, geoId];
        setPickedDepartment(geoId)
        setRegionOnMap(selectedRegion);
        }

    const handleProvinceClick = geoId => () => {
        let selectedProvince = (geoId === '')? [PickedCountry, PickedDepartment]:[PickedCountry, PickedDepartment, geoId];
        setRegionOnMap(selectedProvince);
        }
    
    const handleBackClick=()=>{
        if(RegionOnMap.length===3){
            setRegionOnMap(['Peru',PickedDepartment])
            setPosition({ coordinates: [-75, -15], zoom: 9 })
        }else if(RegionOnMap.length===2){
            setRegionOnMap(['Peru'])
            setPickedDepartment("")
            setPosition({ coordinates: [-75, -15], zoom: 9 })
        }
    }

    function handleZoomIn() {
        if(RegionOnMap.length === 1){
            if (position.zoom >= position.zoom+10) return;
            setPosition((pos) => ({ ...pos, zoom: pos.zoom + 1 }));
        }
        else if(RegionOnMap.length === 2){
            if (position.zoom >= position.zoom+15) return;
            setPosition((pos) => ({ ...pos, zoom: pos.zoom + 1 }));
        }
        else if(RegionOnMap.length === 3){
            if (position.zoom >= position.zoom+15) return;
            setPosition((pos) => ({ ...pos, zoom: pos.zoom + 1 }));
        }
      }
    
    function handleZoomOut() {
    if (position.zoom <= 9) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom - 1 }));
    }

    function handleMoveEnd(position) {
        setPosition(position);
      }


    useEffect(()=>{
        let [ res_geoUrl, res_subregionId] = getShapefileUrl(RegionOnMap);
        setGeoURL(res_geoUrl)
        setSubRegionId(res_subregionId)
    },[RegionOnMap]);

    useEffect(()=>{
        if(MapDataCategory === 'Observed Cases'){
            colorCategory = 'num_positive_cases'
        } else{
            colorCategory = 'num_death_cases'
        }
        setDataDisplayOnMap(extractObservationDataForMapDisplay(PickedCountry, RegionOnMap, ObservationDataMap, colorCategory, MapDataCategory, CensusInfo))

    }, [RegionOnMap, startDate, ObservationDataMap, MapDataCategory])

    return(
        <div className="map" style={{ backgroundColor: '#f8f9fa'}}>
        <div style={{ display: "flex", justifyContent: "space-between"}}>
            {RegionOnMap.length === 1 ? (
                <div>
                <label>{t('Country')} {t(PickedCaseType)}</label><br/>
                {PickedCaseType === 'Daily' ? (
                    <label>{convertDateToEuropeanFormat(startDate)}</label>
                ) : (<label>{convertDateToEuropeanFormat(startDate)} to {convertDateToEuropeanFormat(endDate)}</label>)}
                </div>
            ) : RegionOnMap.length === 2 ? (
                <div>
                <label>{RegionOnMap[1]} {t(PickedCaseType)}</label><br/>
                {PickedCaseType === 'Daily' ? (
                    <label>{convertDateToEuropeanFormat(startDate)}</label>
                ) : (<label>{convertDateToEuropeanFormat(startDate)} to {convertDateToEuropeanFormat(endDate)}</label>)}
                </div>
            ): (
                <div>
                <label>{RegionOnMap[1]} {RegionOnMap[2]} {t(PickedCaseType)}</label><br/>
                 {PickedCaseType === 'Daily' ? (
                    <label>{convertDateToEuropeanFormat(startDate)}</label>
                ) : (<label>{convertDateToEuropeanFormat(startDate)} to {convertDateToEuropeanFormat(endDate)}</label>)}
                </div>
            )}
        <Button variant="dark" style={{width:"64px", height:"25px", 
          marginTop:"-1px", paddingTop:"0px", backgroundColor:'#841617'}}
            onClick={handleBackClick}
        >
            {t('Back')}
        </Button>
        </div>
        <div style={{display: 'flex'}}>
        <ComposableMap projection={getMapProjection(RegionOnMap)} width={width} height={height}>
            <ZoomableGroup zoom={position.zoom} center={position.coordinates} onMoveEnd={handleMoveEnd}>
                <Geographies geography={geoUrl}>
                    {({geographies})=>{
                        if(RegionOnMap.length===1){
                                let paths = geographies.map(geo => {
                                let geoId = getSubRegionId(geo)
                                let g = DataDisplayOnMap[geoId]
                                let dataForDisplay = g!==undefined?
                                [{Department: g['departamento'], ObservedDeaths: g['num_death_cases'], 'DeathRate(1000 Population)': g['population_1000_deaths'], ObservedCases: g['num_positive_cases'], 'CasesRate(1000 Population)': g['population_1000_cases']}]:
                                [{Department: geoId, ObservedDeaths: 0, 'DeathRate(1000 Population)': 0, ObservedCases: 0, 'CasesRate(1000 Population)': 0}]
                                return(
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        style={{
                                            default: {
                                                fill: g===undefined? colorConstants.colorMapDeaths[0] : g.color,
                                                stroke: "#000",
                                                strokeWidth: 0.20,
                                                outline: "none"
                                            },
                                            hover: {
                                                fill: "#AAA",
                                                stroke: "#000",
                                                strokeWidth: 0.20,
                                                outline: "none",
                                            }
                                        }}
                                        onClick={handleCountyClick(geoId)}
                                        onMouseOver={(evt) => {
                                            var left = evt.clientX + 'px';
                                            var top = evt.clientY + 'px';
                                            var div = document.getElementById('map-hover');

                                            setSimulationState(simulationState => {
                                                return({
                                                    ...simulationState,
                                                    hoveredRegion: (dataForDisplay[0]===undefined)? {region: 'NA'} : Object.assign({}, ...Object.entries(dataForDisplay[0]).filter(([k,v]) => !['mapId', 'color', 'time', 'timestr'].includes(k)).map(([k,v]) => ({[k]:v})))
                                                        
                                                })
                                            })
                                            div.style.left = left;
                                            div.style.top = top;
                                            div.style.visibility = 'visible'
                                        }}
                                        onMouseOut={(evt) => {
                                            var div = document.getElementById('map-hover');
                                            div.style.visibility = 'hidden';
                                        }}
                                    />
                                );
                            });
                            return paths;
                    }
                    else if(RegionOnMap.length===2) {
                        let paths = geographies.filter(feature => feature.properties.FIRST_NOMB===RegionOnMap[1]).map(geo => {
                        let geoId = getSubRegionId(geo)
                        let g = DataDisplayOnMap[geoId]
                        let dataForDisplay = g!==undefined?
                        [{Department: g['departamento'], Province: g['provincia'], ObservedDeaths: g['num_death_cases'], ObservedCases: g['num_positive_cases']}]:
                        [{Department: RegionOnMap[1], Province:geoId, ObservedDeaths: 0, ObservedCases: 0}
                            ]
                            return(
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    style={{
                                        default: {
                                            fill: g===undefined? colorConstants.colorMapDeaths[0] : g.color,
                                            stroke: "#000",
                                            strokeWidth: 0.04,
                                            outline: "none"
                                        },
                                        hover: {
                                            fill: "#AAA",
                                            stroke: "#000",
                                            strokeWidth: 0.04,
                                            outline: "none",
                                        }
                                    }}
                                    onClick={handleProvinceClick(geoId)}
                                    onMouseOver={(evt) => {
                                        var left = evt.clientX + 'px';
                                        var top = evt.clientY + 'px';
                                        var div = document.getElementById('map-hover');

                                        setSimulationState(simulationState => {
                                            return({
                                                ...simulationState,
                                                hoveredRegion: (dataForDisplay[0]===undefined)? {region: 'NA'} : Object.assign({}, ...Object.entries(dataForDisplay[0]).filter(([k,v]) => !['mapId', 'color', 'time', 'timestr'].includes(k)).map(([k,v]) => ({[k]:v})))
                                            })
                                        })
                                        div.style.left = left;
                                        div.style.top = top;
                                        div.style.visibility = 'visible'
                                    }}
                                    onMouseOut={(evt) => {
                                        var div = document.getElementById('map-hover');
                                        div.style.visibility = 'hidden';
                                    }}
                                />
                            );
                        });
                        return paths;
                    }else if(RegionOnMap.length===3) {
                        let paths = geographies.filter(feature => feature.properties.NOMBPROV===RegionOnMap[2]).map(geo => {
                           let geoId = getSubRegionId(geo)
                           let g = DataDisplayOnMap[geoId]
                           let dataForDisplay = g!==undefined?
                            [{Department: g['departamento'], Province: g['provincia'], District: g['distrito'], ObservedDeaths: g['num_death_cases'], ObservedCases: g['num_positive_cases']}]:
                            [{Department: RegionOnMap[1], Province:RegionOnMap[2], District: geoId, ObservedDeaths: 0, ObservedCases: 0}]
                            return(
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    style={{
                                        default: {
                                            fill: g===undefined? colorConstants.colorMapDeaths[0] : g.color,
                                            stroke: "#000",
                                            strokeWidth: 0.01,
                                            outline: "none"
                                        },
                                        hover: {
                                            fill: "#AAA",
                                            stroke: "#000",
                                            strokeWidth: 0.01,
                                            outline: "none",
                                        }
                                    }}
                                    onMouseOver={(evt) => {
                                        var left = evt.clientX + 'px';
                                        var top = evt.clientY + 'px';
                                        var div = document.getElementById('map-hover');

                                        setSimulationState(simulationState => {
                                            return({
                                                ...simulationState,
                                                hoveredRegion: (dataForDisplay[0]===undefined)? {region: 'NA'} : Object.assign({}, ...Object.entries(dataForDisplay[0]).filter(([k,v]) => !['mapId', 'color', 'time', 'timestr'].includes(k)).map(([k,v]) => ({[k]:v})))
                                            })
                                        })
                                        div.style.left = left;
                                        div.style.top = top;
                                        div.style.visibility = 'visible'
                                    }}
                                    onMouseOut={(evt) => {
                                        var div = document.getElementById('map-hover');
                                        div.style.visibility = 'hidden';
                                    }}
                                />
                            );
                        });
                        return paths;
                    }
                }
            } 
                </Geographies>
            </ZoomableGroup>
        </ComposableMap>
        {MapDataCategory === 'Observed Cases' ? (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginRight: '4px',width: '0px'}}>
    <label style={{marginTop: '100px'}}>
      {t('CASES')}
    </label>
    <label>
      {t('High')}
    </label>
      <img src="CasesScale.png" alt="scaleR" style={{borderStyle: 'solid', borderWidth: '1px', transform: 'rotate(-90deg)', transformOrigin: 'right center', height: '15px'}}
      />
    <label style={{ marginTop: '119px'}}>
      {t('Low')}
    </label>
  </div>
) : (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end',  marginRight: '3px',width: '0px' }}>
    <label style={{marginTop: '100px'}}>
      {t('DEATHS')}
    </label>
    <label>
    {t('High')}
    </label>
    <img src="DeathScale.png" alt="scaleR" style={{borderStyle: 'solid', borderWidth: '1px', transform: 'rotate(-90deg)', transformOrigin: 'right center', height: '15px'}}
  />
    <label style={{ marginTop: '119px'}}>
    {t('Low')}
    </label>
  </div>
  
)}
</div>

        <div className="controls" style={{marginTop: "-253px"}}>
        <button onClick={handleZoomIn}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="3"
          >
            <line x1="12" y1="5" x2="12" y2="19" />
            <line x1="5" y1="12" x2="19" y2="12" />
          </svg>
        </button>
        <button onClick={handleZoomOut}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="3"
          >
            <line x1="5" y1="12" x2="19" y2="12" />
          </svg>
        </button>
      </div>
        </div>
    );
}
export default Map;