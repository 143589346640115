const convertDate2Str = (dateObj) => {
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Month is 0-based
    const day = String(dateObj.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  
const convertDateToEuropeanFormat = (dateObj) => {
  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Month is 0-based
  const year = dateObj.getFullYear();
  return `${day}/${month}/${year}`;
};

export { convertDate2Str,  convertDateToEuropeanFormat};