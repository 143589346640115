import React from "react";
import MapControlPanelBottom from './MapControlPanelBottom'
import Map from "./Map";
import MapBottomView from "./MapBottomView";
import Stack from 'react-bootstrap/Stack';
import { useTranslation } from 'react-i18next';

function MapView() {
    const { t } = useTranslation();
    return (
    <div key="Panviz-mapview">
        <Stack>
            <h3 style={{textAlign: 'center'}}>{t('INTERACTIVE MAP')}</h3>
            <Map/>
            <MapBottomView />
            <MapControlPanelBottom />
        </Stack>
    </div>
    );

}

export default MapView;