import React, { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import './languagePicker.css';

const LanguagePicker = () => {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState('en');
    const flag = language === 'es' ? '🇵🇪' : '🇺🇸'

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
    }

    return (
        <div className="language-picker" style={{textAlign:'right'}}>
        <DropdownButton  className="language-picker--dropdown" title={flag} >
            <Dropdown.Item onClick={() => handleLanguageChange('es')}>Español</Dropdown.Item>
            <Dropdown.Item onClick={() => handleLanguageChange('en')}>English</Dropdown.Item>
        </DropdownButton>
        </div>
    );

}

export default LanguagePicker;
