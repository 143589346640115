import React, {useContext, useState, useRef, useEffect} from 'react'
import { GlobalContext } from '../GlobalContext';
import { useTranslation } from 'react-i18next';


const EventsRegionOptionPanel = () => {
  const { t } = useTranslation();
  const {InterventionType, setInterventionType} = useContext(GlobalContext)


const selectInterventionType = ((e)=>{
  setInterventionType(e.target.value)
})

return (
        <div onChange={selectInterventionType} style={{float:'left'}}>
          <input type="radio" name="interventionType" value="Peru" defaultChecked/>
            &nbsp;Peru&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <input type="radio" name="interventionType" value="Arequipa"/>
            &nbsp;Arequipa&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
)}

export default EventsRegionOptionPanel;

