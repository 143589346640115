import axios from "axios";

import { getAuthorizationHeader } from "../utils";
import { AUTH_API_URL } from "../API";

const register = (email, password) => {
  return axios.post(AUTH_API_URL + "register", {
    email,
    password,
  });
};
const login = (email, password) => {
  return axios
    .post(AUTH_API_URL + "login", {
      email,
      password,
    })
    .then((response) => {
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("token", JSON.stringify(response.data.token));
      return response.data;
    });
};
const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
};
const getUsers = () => {
  return axios({
    mathod: "GET",
    url: AUTH_API_URL + "all",
    headers: {
      "Authorization": getAuthorizationHeader()
    },
  }).then((response) => {
    return response.data;
  });
};
const activateUser = (id, activate) => {
  return axios({
      method: "PUT",
      url: AUTH_API_URL + "activate/" + id,
      headers: {
          "Authorization": getAuthorizationHeader()
      },
      data: {
          activate,
      },
  });
};
const deleteUser = (id) => {
  return axios({
      method: "DELETE",
      url: AUTH_API_URL + "delete/" + id,
      headers: {
          "Authorization": getAuthorizationHeader()
      },
  });
};
const changeRole = (id, role) => {
  return axios({
      method: "PUT",
      url: AUTH_API_URL + "change_role/" + id,
      headers: {
          "Authorization": getAuthorizationHeader()
      },
      data: {
          role,
      },
  });
};

export default {
  register,
  login,
  logout,
  getUsers,
  activateUser,
  deleteUser,
  changeRole
};
