import React from 'react'
import {withResizeDetector} from 'react-resize-detector';
import EventEditor from './EventEditor.js'
import EventBarChart from './EventBarChart'
import { useTranslation } from 'react-i18next';

const AdaptiveEventsView = (({ width, height }) =>{
    const { t } = useTranslation();
    return (
        <div id="EventsView" className="w-500 h-100">
            <h3 style={{textAlign: 'center', marginTop: "10px"}}>{t('INTERVENTION PLANNING')}</h3>
            <div style={{backgroundColor: "#F7F7F7"}}>
            <EventBarChart width={width} height={500}/>    
            </div> 
            <EventEditor></EventEditor>
        </div>);
});
const EventsView = withResizeDetector(AdaptiveEventsView);

export default EventsView
